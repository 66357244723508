interface FileInputProps {
  // input id 로 사용
  // 따라서 button click handler 에서 id 값 클릭해야 함
  inputId: string;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const FileInput: React.FC<FileInputProps> = ({ inputId, setFile }) => {
  const onFileInputClickHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
    }
  };
  return (
    <input
      id={inputId}
      className="sr-only"
      name="file-upload"
      type="file"
      multiple={false}
      onChange={onFileInputClickHandler}
    />
  );
};

export default FileInput;
