import { MailIcon, RefreshIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { Component, ErrorInfo, ReactNode } from 'react';
import { MainContainer } from '../Container';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <MainContainer header={{ title: 'Something Went Wrong | SWYG' }}>
          <main className="md:py-18 relative mx-auto flex w-full max-w-7xl flex-col items-center py-24 ">
            <section className="space-y-8 py-10">
              <h1 className="font-logo text-xl font-semibold tracking-wider text-swygBlue-500 sm:text-3xl">
                페이지를 불러오는 중 오류가 발생했습니다.
              </h1>
              <h2 className="flex flex-col space-y-1 pb-4 text-center text-lg sm:text-xl">
                <p>다시 시도하기 이후에도 문제가 해결되지 않으면</p>
                <p>스위그에 문의해 주시기 바랍니다.</p>
              </h2>
            </section>

            <section className="relative mx-auto space-y-4">
              <button
                onClick={() => window.location.reload()}
                className="btn-container btn-animation w-96 justify-center space-x-2 rounded bg-swygBlue-500 text-center text-base text-gray-100"
              >
                다시 시도하기
              </button>
              <Link href={`https://tally.so/r/wo2qoV`}>
                <a
                  target="_blank"
                  className="flex items-center justify-center space-x-2 py-2.5 text-base text-swygBlue-500 hover:opacity-70"
                >
                  <div>문의하기</div>
                </a>
              </Link>
            </section>
          </main>
        </MainContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
