import { Loading3QuartersOutlined } from '@ant-design/icons';

interface SpinnerProps {
  color?: string;
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return <Loading3QuartersOutlined spin className={className} />;
};

export default Spinner;
