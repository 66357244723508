import { MinusCircleIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import { ExpertiseLevel } from '../../../lib/types/enums';
import { ToolRow } from '../../../pages/profiles/edit/tools';

interface ToolsAddRowProps {
  tools: ToolRow[];
  onToolNameChangeHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
  ) => void;
  onToolDropDownChangeHandler: (
    type: 'expertiseLevel' | 'career',
    e: React.ChangeEvent<HTMLSelectElement>,
    i: number,
  ) => void;
  onDeleteButtonClick: (i: number, toolName: string) => void;
}

const ExpertiseLevels = [
  { value: ExpertiseLevel.INTERESTED, name: '관심있음' },
  { value: ExpertiseLevel.LOW, name: '초급' },
  { value: ExpertiseLevel.MIDDLE, name: '중급' },
  { value: ExpertiseLevel.HIGH, name: '고급' },
  { value: ExpertiseLevel.EXPERT, name: '전문가' },
];

const ToolsAddRow: React.FC<ToolsAddRowProps> = ({
  tools,
  onToolNameChangeHandler,
  onToolDropDownChangeHandler,
  onDeleteButtonClick,
}) => {
  const ToolRows = (numberOfRows: number) => {
    const rows = [];
    const expertiseLevelOptions = [];
    const careerOptions = [];

    // 전문성 select options
    for (const level of ExpertiseLevels) {
      expertiseLevelOptions.push(
        <option key={`level-${level.value}`} value={level.value}>
          {level.name}
        </option>,
      );
    }

    // 경력 select options
    for (let i = 0; i <= 11; i++) {
      careerOptions.push(
        <option key={`career-${i}`} value={i}>
          {i === 11 ? `10년 이상` : i === 0 ? '1년 미만' : `${i}년`}
        </option>,
      );
    }

    for (let i = 0; i < numberOfRows; i++) {
      rows.push(
        <Fragment key={`tools-${i}`}>
          <div className="edit-container col-span-6 p-0 px-2">
            <input
              className={`w-full bg-transparent focus:outline-none`}
              name={`toolName-${i}`}
              value={tools[i].toolName}
              onChange={(e) => onToolNameChangeHandler(e, i)}
              placeholder="기술 입력"
              autoComplete="off"
              maxLength={30}
              type="text"
            />
          </div>
          <div className={`edit-container col-span-2 p-0 px-2`}>
            <select
              onChange={(e) =>
                onToolDropDownChangeHandler('expertiseLevel', e, i)
              }
              className="w-full cursor-pointer bg-transparent py-2 focus:outline-none"
              value={tools[i].expertiseLevel}
            >
              <option disabled value="">
                선택
              </option>
              {expertiseLevelOptions}
            </select>
          </div>
          <div className={`edit-container col-span-2 p-0 px-2`}>
            <select
              onChange={(e) => onToolDropDownChangeHandler('career', e, i)}
              className="w-full cursor-pointer bg-transparent py-2 focus:outline-none"
              value={tools[i].careerYear}
            >
              <option disabled value={-1}>
                선택
              </option>
              {careerOptions}
            </select>
          </div>

          <button
            className="col-span-1 flex items-center justify-center p-0 px-2"
            onClick={() => onDeleteButtonClick(i, tools[i].toolName)}
          >
            <MinusCircleIcon width={15} height={15} className="text-rose-500" />
          </button>
        </Fragment>,
      );
    }

    return rows;
  };

  return (
    <div className="grid grid-cols-11 gap-x-2 gap-y-4">
      {ToolRows(tools.length)}
    </div>
  );
};

export default ToolsAddRow;
