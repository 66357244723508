import { XIcon } from '@heroicons/react/outline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface TagsContainerProps {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  /** default 15 */
  tagMaxLength?: number;
  type?: 'default' | 'tag';
}

const TagsContainer: React.FC<TagsContainerProps> = ({
  tags,
  setTags,
  tagMaxLength = 15,
  type = 'default',
}) => {
  const handleChange = (result: any) => {
    if (!result.destination) return;
    const items = [...tags];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setTags(items);
  };

  const onTagDeleteButtonClick = (name: string) => {
    const removedTags = tags.filter((tag) => tag !== name);
    setTags(removedTags);
  };

  return (
    <>
      <div className="space-y-3">
        <div className="text-xs text-gray-500">
          {tags.length}/{tagMaxLength}
        </div>
        {type === 'tag' ? (
          <DragDropContext onDragEnd={handleChange}>
            <Droppable droppableId="tagList" direction="horizontal">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-wrap"
                >
                  {tags.map((tag, index) => (
                    <Draggable
                      key={`tag-${tag}`}
                      draggableId={`tag-${tag}`}
                      index={index}
                    >
                      {(provided) => {
                        return (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            className={`btn-container relative mr-3 mb-4 flex h-[26px] items-center space-x-1 rounded-3xl pl-[10px] pr-[26px] md:pr-[10px] ${
                              index < 3
                                ? 'bg-swygBlue-50 text-swygBlue-500'
                                : 'bg-gray-100 text-gray-500'
                            }`}
                          >
                            <div className="text-xs">{tag}</div>
                            <button
                              onClick={() => onTagDeleteButtonClick(tag)}
                              className="group absolute -right-[7px] shrink-0 p-4 md:relative md:right-0 md:p-0"
                            >
                              <div
                                className={`${
                                  index < 3
                                    ? 'bg-swygBlue-400 hover:bg-swygBlue-500'
                                    : 'bg-gray-400 group-hover:bg-gray-500'
                                } flex h-3 w-3 items-center justify-center rounded-full`}
                              >
                                <XIcon className="h-[10px] w-[10px] text-white" />
                              </div>
                            </button>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className="flex max-w-xl flex-wrap pt-2 pr-4 lg:max-w-2xl xl:max-w-3xl">
            {tags.map((tag) => (
              <div
                key={`tag-${tag}`}
                className="btn-container mr-3 mb-3 flex space-x-1 rounded-full border border-gray-300 bg-gray-200 bg-opacity-80 py-2 pl-4 pr-2 font-bold text-gray-800"
              >
                <span>{tag}</span>
                <button
                  onClick={() => onTagDeleteButtonClick(tag)}
                  className="group shrink-0 rounded-full p-1 hover:bg-gray-500 hover:bg-opacity-60"
                >
                  <XIcon className="text-gray-white h-3 w-3 group-hover:text-gray-100" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TagsContainer;
