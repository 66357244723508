import Link from 'next/link';
import Image from 'next/image';
import { forwardRef } from 'react';

const Footer = forwardRef(
  (props: any, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <div ref={ref} className="w-full bg-gray-50 text-xs">
        <div className="max-w-7xl px-8 py-10 lg:px-20">
          <div className="flex flex-col md:grid md:grid-cols-4 md:gap-x-10">
            <Link href="/">
              <a className="relative h-8 w-16 hover:opacity-40">
                <Image
                  src="/assets/img/logo/logo_ver2.svg"
                  alt="SWYG Logo"
                  layout="fill"
                  objectFit="contain"
                />
              </a>
            </Link>

            <div className="block space-y-1 pt-5 md:hidden">
              <div>Show What You Got</div>
              <div>나의 아이디어를 마음껏 펼쳐보세요.</div>
              <Link href="https://instagram.com/swyg_official">
                <a
                  target="_blank"
                  className="inline-block pt-2 opacity-70 hover:opacity-100 focus:opacity-100"
                >
                  <Image
                    src={`/assets/img/svg/logo-instagram.svg`}
                    alt={`instagram-icon`}
                    width={20}
                    height={20}
                  />
                </a>
              </Link>
            </div>

            <ul className="col-span-3 flex items-center pt-10 text-gray-500 md:pt-0">
              <Link
                href={`https://teamswyg.notion.site/9c2adf208c104df39a85355a42833081?pvs=4`}
              >
                <a
                  target="_blank"
                  className="mr-10 hover:text-gray-800 hover:underline focus:text-gray-800"
                >
                  이용약관
                </a>
              </Link>
              <Link
                href={`https://teamswyg.notion.site/0dc7d9ad98494338bbb1b8af65889677?pvs=4`}
              >
                <a
                  target="_blank"
                  className="mr-8 font-bold hover:text-gray-800 hover:underline focus:text-gray-800"
                >
                  개인정보처리방침
                </a>
              </Link>

              <div className="mr-8 h-1/2 border-l border-gray-300" />

              <Link href={`https://tally.so/r/wo2qoV`}>
                <a
                  target="_blank"
                  className="hover:text-gray-800 hover:underline focus:text-gray-800"
                >
                  문의하기
                </a>
              </Link>
            </ul>

            <div className="col-span-1 hidden space-y-1 pt-3 font-logo text-gray-600 md:block">
              <div>Show What You Got</div>
              <div>나의 아이디어를 마음껏 펼쳐보세요.</div>
              <Link href="https://instagram.com/swyg_official">
                <a
                  target="_blank"
                  className="inline-block pt-2 opacity-70 hover:opacity-100 focus:opacity-100"
                >
                  <Image
                    src={`/assets/img/svg/logo-instagram.svg`}
                    alt={`instagram-icon`}
                    width={20}
                    height={20}
                  />
                </a>
              </Link>
            </div>

            <div className="col-span-3 flex flex-col space-y-1 pt-5 text-gray-500">
              <div>주식회사 스위그 | 대표: 서경민</div>
              <div>사업자등록번호: 476-86-02913</div>
              <div>
                경기도 성남시 분당구 대왕판교로645번길 12, 경기창조경제혁신센터
                8층
              </div>
              <div className="pt-4">
                &copy; 2024{' '}
                <Link href="https://www.swygbro.com">
                  <a className="font-logo">SWYG.</a>
                </Link>{' '}
                All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Footer.displayName = 'footer';

export default Footer;
