import {
  HomeIcon,
  LogoutIcon,
  PencilAltIcon,
  UserCircleIcon,
  ViewBoardsIcon,
} from '@heroicons/react/outline';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Image from 'next/image';
import Link from 'next/link';
import { useMutation, useQuery } from 'react-query';
import { logoutApi } from '../../../lib/apis/auth';
import { deactivateUserApi, getMyTeamsApi } from '../../../lib/apis/users';
import { GetMyTeamsResponse } from '../../../lib/apis/users/response';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { logout } from '../../../redux/slices/users/usersSlice';
import { Spinner } from '../../Loader';

interface ProfileContainerProps {
  location?: 'main' | 'dashboard';
}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  location = 'main',
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users);
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  const { data: teamsData } = useQuery<GetMyTeamsResponse[]>(
    ['teams'],
    getMyTeamsApi,
  );
  const { mutate: mutateLogout, isLoading } = useMutation(
    ['logout'],
    logoutApi,
    {
      onError: (e: AxiosError) => axiosErrorHandler(e),
      onSuccess: () => {
        dispatch(logout());
        Cookies.remove('token', {
          domain: isProduction
            ? '.swygbro.com'
            : isPreview
            ? 'swygbro.com'
            : '',
          path: '/',
        });
      },
    },
  );
  const { mutate: mutateDeactivateUser } = useMutation(
    ['deactivateUser'],
    deactivateUserApi,
    {
      onError: (e: AxiosError) => axiosErrorHandler(e),
    },
  );

  const onLogoutButtonClick = () => {
    mutateDeactivateUser();
    mutateLogout();
  };

  return (
    <div className="group relative z-50 text-sm text-gray-500">
      <Link href={`/profiles/${user.userId}`}>
        <a className="profile-image-container flex h-9 w-9 rounded-full">
          <Image
            src={
              user.profileImage.length
                ? user.profileImage
                : '/assets/img/avatars/guest.png'
            }
            alt="profile image"
            layout="fill"
            objectFit="cover"
            className="rounded-full"
          />
        </a>
      </Link>

      {/* Pop Up */}
      <div className="absolute right-0 hidden transform group-hover:block">
        <div className="py-1" />
        {/* white board */}
        <ul className="w-64 rounded-md bg-white py-1 shadow-popOver">
          <h3 className="sr-only">메인 and 대시보드</h3>
          <li className="w-full hover:bg-gray-100">
            <Link href={location === 'main' ? '/dashboard' : '/'}>
              <a className="flex items-center space-x-4 py-4 px-6">
                {location === 'main' ? (
                  <ViewBoardsIcon className="h-6 w-6" />
                ) : (
                  <HomeIcon className="h-6 w-6" />
                )}
                <div>{location === 'main' ? '대시보드' : '홈'}</div>
              </a>
            </Link>
          </li>

          {teamsData?.length ? (
            <>
              <div className="border-b" />
              <h3 className="sr-only">팀</h3>

              {teamsData.map((myTeam) => {
                const { team } = myTeam;
                const { uniqueId, teamLogo, teamName } = team;

                return (
                  <li
                    key={`team-${uniqueId}`}
                    className="w-full hover:bg-gray-100"
                  >
                    <Link href={`/teams/${uniqueId}`}>
                      <a className="flex items-center space-x-4 py-4 px-6">
                        <div className="relative h-6 w-6 rounded-full">
                          <Image
                            alt={`${teamName}-logo`}
                            src={
                              teamLogo.fileLocation
                                ? teamLogo.fileLocation
                                : '/assets/img/logo/mobile/logo_square_180.png'
                            }
                            layout="fill"
                            objectFit="cover"
                            className="rounded-full"
                          />
                        </div>
                        <div>{teamName}</div>
                      </a>
                    </Link>
                  </li>
                );
              })}
            </>
          ) : null}

          <div className="border-b" />
          <h3 className="sr-only">프로필</h3>

          <li className="w-full hover:bg-gray-100">
            <Link href={`/profiles/${user.userId}`}>
              <a className="flex items-center space-x-4 py-4 px-6">
                <UserCircleIcon className="h-6 w-6" />
                <div>내 프로필</div>
              </a>
            </Link>
          </li>

          <li className="w-full hover:bg-gray-100">
            <Link href="/profiles/edit/public">
              <a className="flex items-center space-x-4 py-4 px-6">
                <PencilAltIcon className="h-6 w-6" />
                <div>프로필 편집</div>
              </a>
            </Link>
          </li>

          <div className="border-b" />
          <h3 className="sr-only">로그아웃</h3>

          <li className="w-full hover:bg-gray-100">
            <Link href="/">
              <a onClick={onLogoutButtonClick} className={`flex py-4 px-6`}>
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div className="flex items-center space-x-4">
                    <LogoutIcon className="h-6 w-6" />
                    <div>로그아웃</div>
                  </div>
                )}
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileContainer;
