import { useRef } from 'react';

const useScrollBlock = () => {
  const scroll = useRef(false);

  const blockScroll = () => {
    const html = document.documentElement;
    const { body } = document;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = ''; /* [1] */
    body.style.position = ''; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.overflow = 'hidden'; /* [2] */

    scroll.current = true;
  };

  const allowScroll = () => {
    const html = document.documentElement;
    const { body } = document;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';

    scroll.current = false;
  };

  return [blockScroll, allowScroll];
};

export { useScrollBlock };
