import {
  DotsVerticalIcon,
  HeartIcon as HeartOutlineIcon,
  PencilAltIcon,
  SpeakerphoneIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/solid';
import { AxiosError } from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  deleteCommentApi,
  toggleCommentFavApi,
  updateCommentApi,
} from '../../../lib/apis/projects';
import { useClickOutside } from '../../../lib/customHooks';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { elapsedTime } from '../../../lib/functions/utils';
import { Comment, Member } from '../../../lib/types/project';
import { useAppSelector } from '../../../redux/app/hooks';
import { CommentInput } from '../../Input';

interface ReplyContainerProps {
  replyData: Comment[];
  reply: Comment;
  members: Member[];
  setIsReportShown: Dispatch<SetStateAction<boolean>>;
  setReportedCommentId: Dispatch<SetStateAction<number>>;
  setReportedUserId: Dispatch<SetStateAction<string>>;
  setUpdatedReplyData: Dispatch<SetStateAction<Comment[] | undefined>>;
}

const ReplyContainer: React.FC<ReplyContainerProps> = ({
  replyData,
  reply,
  members,
  setIsReportShown,
  setReportedCommentId,
  setReportedUserId,
  setUpdatedReplyData,
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.users);
  const [replyContext, setReplyContext] = useState('');
  const [isFav, setIsFav] = useState(false);
  const [favCount, setFavCount] = useState(0);
  const [isReplyEditMode, setIsReplyEditMode] = useState(false);
  const [editContext, setEditContext] = useState('');
  const { isVisible, ref, setIsVisible } = useClickOutside(false);
  const { mutate: mutateToggleReplyFav } = useMutation(
    ['toggleReplyFav'],
    toggleCommentFavApi,
    {
      onSuccess: () => {
        isFav
          ? setFavCount((prevState) => prevState - 1)
          : setFavCount((prevState) => prevState + 1);
        setIsFav((prevState) => !prevState);
      },
      onError: (e: AxiosError) => {
        axiosErrorHandler(e);
        router.push('/auth/login');
      },
    },
  );
  const { mutate: mutateUpdateReply } = useMutation(
    ['updateReply', reply.id],
    () => updateCommentApi({ commentId: reply.id, context: editContext }),
    {
      onSuccess: () => {
        setReplyContext(editContext);
      },
    },
  );
  const { mutate: mutateDeleteReply } = useMutation(
    ['deleteReply', reply.id],
    () => deleteCommentApi({ commentId: reply.id }),
    {
      onSuccess: (removedReply) => {
        alert('삭제가 완료되었습니다.');
        queryClient.invalidateQueries('comment');
        setUpdatedReplyData(
          replyData?.filter((reply) => reply.id != removedReply.data.id),
        );
      },
    },
  );

  useEffect(() => {
    if (reply) {
      const { favUsers } = reply;
      if (user.isLoggedIn) {
        const favUsersId = favUsers?.map((user) => user.id);
        if (favUsersId?.includes(user.userId)) {
          setIsFav(true);
        }
      }
      setFavCount(favUsers?.length || 0);
    }
  }, [reply, user, setIsFav, setFavCount]);

  useEffect(() => {
    setReplyContext(reply.context);
  }, [setReplyContext, reply]);

  const onDotsButtonClick = () => {
    setIsVisible((prevState) => !prevState);
  };

  const onEditButtonClick = (context: string) => {
    setIsVisible(false);
    setIsReplyEditMode(true);
    setEditContext(context);
  };

  const onSaveButtonClick = () => {
    mutateUpdateReply();
    setIsReplyEditMode(false);
  };

  const onDeleteButtonClick = () => {
    const confirm = window.confirm('답글을 삭제하시겠습니까?');
    if (confirm) {
      mutateDeleteReply();
    }
  };

  const onReportButtonClick = () => {
    setReportedCommentId(reply.id);
    setReportedUserId(reply.user.id);
    setIsReportShown(true);
  };

  return (
    <div key={reply.id} className="group flex w-full space-x-3 text-xs">
      <Link href={`/profiles/${reply.user.id}`}>
        <a className="profile-image-container h-10 w-10 flex-shrink-0 bg-gray-100 hover:shadow-profileContainer">
          <Image
            src={reply.user.profileImage.fileLocation}
            alt={`${reply.user.username} Profile Image`}
            layout="fill"
            objectFit="cover"
            priority
          />
        </a>
      </Link>

      <div className="flex w-full flex-col">
        <div className="space-y-3">
          <div className="flex items-center space-x-2">
            <div className="space-x-1 font-semibold">
              <span className="text-xs">
                {reply.user.role === 'planner' ? '기획' : '개발'}
              </span>
              <Link href={`/profiles/${reply.user.id}`}>
                <a className="text-base hover:opacity-70">
                  {reply.user.username}
                </a>
              </Link>
            </div>
            {members.find((member) => reply.user.id === member.user.id) && (
              <div className="flex h-[18px] w-[37px] items-center justify-center rounded-base border border-swygBlue-500 text-[10px] text-swygBlue-500">
                제작자
              </div>
            )}
            <div className="text-gray-600">{elapsedTime(reply.updatedAt)}</div>
          </div>

          {!isReplyEditMode ? (
            <pre className="whitespace-pre-wrap font-kr text-xs leading-8 text-gray-900">
              {replyContext}
            </pre>
          ) : (
            <CommentInput
              type="reply"
              isEditMode
              prevValue={replyContext}
              value={editContext}
              onChange={setEditContext}
              onSaveButtonClick={onSaveButtonClick}
              onCancelButtonClick={() => setIsReplyEditMode(false)}
            />
          )}
        </div>

        <div className="relative flex w-full items-center justify-between">
          <div className="flex items-center space-x-[2px]">
            <button
              onClick={() => mutateToggleReplyFav({ commentId: reply.id })}
              className="-ml-2 px-2 py-[6px]"
            >
              <div className="flex items-center space-x-1">
                {isFav ? (
                  <HeartSolidIcon
                    width={24}
                    height={24}
                    className="text-rose-400"
                  />
                ) : (
                  <HeartOutlineIcon width={24} height={24} />
                )}
                <span className="text-sm font-semibold">{favCount}</span>
              </div>
            </button>
          </div>

          <div className="flex flex-col justify-end">
            <button
              onClick={onDotsButtonClick}
              className="-m-2 rounded-full p-2 hover:bg-gray-100 lg:hidden lg:group-hover:block"
            >
              <DotsVerticalIcon width={24} height={24} />
            </button>
            <div
              ref={ref}
              className={`${isVisible ? '' : 'hidden'} ${
                reply.user.id === user.userId
                  ? '-bottom-[63px]'
                  : '-bottom-[38px]'
              } absolute right-0 flex items-center justify-center rounded-base border bg-white py-[6px] text-[10px] font-semibold text-gray-500`}
            >
              {reply.user.id === user.userId ? (
                <div className="flex flex-col space-y-[1px] text-center">
                  <button
                    onClick={() => onEditButtonClick(reply.context)}
                    className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                  >
                    <PencilAltIcon width={20} height={20} />
                    <div>수정</div>
                  </button>
                  <button
                    onClick={onDeleteButtonClick}
                    className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                  >
                    <TrashIcon width={20} height={20} />
                    <div>삭제</div>
                  </button>
                </div>
              ) : (
                <button
                  onClick={onReportButtonClick}
                  className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                >
                  <SpeakerphoneIcon width={20} height={20} />
                  <div>신고</div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyContainer;
