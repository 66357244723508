import { MouseEvent } from 'react';

interface TextInputProps {
  id?: string;
  name: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  className?: string;
  maxLength?: number;
  onClick?: (e: MouseEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onInput?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  placeholder = '',
  type = 'text',
  autoComplete = 'off',
  className,
  maxLength,
  onClick,
  onKeyDown,
  onKeyPress,
  onInput,
  onBlur,
  onFocus,
  disabled = false,
  autoFocus = false,
}) => {
  return (
    <input
      className={`w-full bg-transparent focus:outline-none ${className}`}
      id={id}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      maxLength={maxLength}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      onInput={onInput}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={disabled}
      autoFocus={autoFocus}
    />
  );
};

export default TextInput;
