import { XCircleIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useAppSelector } from '../../../redux/app/hooks';

interface CommentInputProps {
  type: 'comment' | 'reply';
  isEditMode?: boolean;
  prevValue?: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  onSaveButtonClick: () => void;
  onCancelButtonClick?: () => void;
}

const CommentInput: React.FC<CommentInputProps> = ({
  type,
  isEditMode,
  prevValue,
  value,
  onChange,
  onSaveButtonClick,
  onCancelButtonClick,
}) => {
  const user = useAppSelector((state) => state.users);
  const router = useRouter();
  const textArea = useRef<HTMLTextAreaElement>(null);

  const onTextareaClick = () => {
    if (!user.isLoggedIn) {
      alert('로그인 후 이용 바랍니다.');
    }
  };

  const handleAutoHeight = () => {
    if (textArea.current) {
      textArea.current.style.height = 'auto';
      textArea.current.style.height = `${textArea.current.scrollHeight}px`;
    }
  };

  return (
    <>
      {isEditMode ? (
        <div className="flex w-full items-start space-x-[7px]">
          <div className="flex w-full justify-between rounded-base border px-3 py-2 text-xs leading-8 text-gray-900">
            <textarea
              ref={textArea}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              onInput={handleAutoHeight}
              className="w-full resize-none bg-transparent focus:outline-none"
            ></textarea>
          </div>
          <div className="flex space-x-1 text-[10px] font-semibold text-gray-500">
            <button
              onClick={onCancelButtonClick}
              className="flex h-[25px] w-[39px] items-center justify-center rounded-md shadow-commentButton transition hover:bg-gray-100 active:bg-gray-300"
            >
              취소
            </button>
            <button
              onClick={onSaveButtonClick}
              className="flex h-[25px] w-[39px] items-center justify-center rounded-md bg-swygBlue-500 text-white shadow-commentButton transition hover:bg-swygBlueButton-hover active:bg-swygBlueButton-pressed disabled:bg-white disabled:text-gray-500"
              disabled={!value || value === prevValue}
            >
              저장
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`flex w-full items-start space-x-2 text-sm ${
            type === 'comment' && 'mt-4 mb-10 md:mb-[22px]'
          }`}
        >
          <div className="group relative flex w-full items-start space-x-2 rounded-lg bg-gray-100 py-3 pl-4">
            <textarea
              ref={textArea}
              value={value}
              onClick={onTextareaClick}
              onChange={(e) => onChange(e.target.value)}
              onInput={handleAutoHeight}
              placeholder={
                type === 'comment'
                  ? '댓글을 남겨주세요 :)'
                  : '답글을 남겨주세요 :)'
              }
              rows={1}
              className="w-full resize-none bg-transparent focus:outline-none"
            ></textarea>
            <button
              onClick={() => onChange('')}
              className={`${
                value === '' ? 'hidden' : 'flex'
              } absolute top-0 right-0 h-11 w-11 items-center justify-center`}
            >
              <XCircleIcon height={16} width={16} />
            </button>
          </div>
          <button
            onClick={onSaveButtonClick}
            className="flex h-11 w-16 items-center justify-center rounded-lg bg-swygBlue-500 text-sm text-white transition hover:bg-swygBlueButton-hover active:bg-swygBlueButton-pressed disabled:bg-gray-100 disabled:text-gray-900"
            disabled={!value}
          >
            입력
          </button>
        </div>
      )}
    </>
  );
};

export default CommentInput;
