import { Dispatch, SetStateAction } from 'react';

interface ImageInputProps {
  // input id 로 사용
  // 따라서 button click handler 에서 id 값 클릭해야 함
  inputId: string;
  setFile: Dispatch<SetStateAction<File | undefined>>;
  setImageUrl: Dispatch<
    SetStateAction<string | ArrayBuffer | null | undefined>
  >;
}

const ImageInput: React.FC<ImageInputProps> = ({
  inputId,
  setFile,
  setImageUrl,
}) => {
  const onFileInputClickHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    if (files && files.length > 0) {
      const file = files[0];
      const [fileType, _] = file.type.split('/');
      if (fileType !== 'image') {
        alert('이미지 파일만 업로드 할 수 있습니다.');
      } else {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          setImageUrl(e.target?.result);
        };
        fileReader.readAsDataURL(file);
        setFile(file);
      }
    }
  };

  return (
    <input
      accept="image/*"
      id={inputId}
      className="sr-only"
      name="file-upload"
      type="file"
      multiple={false}
      onChange={onFileInputClickHandler}
    />
  );
};

export default ImageInput;
