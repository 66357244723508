import { AxiosError } from 'axios';

export const axiosErrorHandler = (e: AxiosError) => {
  let errorMessage: string;
  errorMessage = e.response
    ? e.response.data.message
    : '네트워크 에러\n잠시 후 다시 시도해 주세요.';
  if (e?.response?.data.message === 'Unauthorized') {
    errorMessage = '로그인 후 이용 바랍니다.';
  }
  alert(errorMessage);
};
