import Head from 'next/head';

export interface HeaderProps {
  title?: string;
  favIcon?: string;
  description?: string;
  keywords?: string;
  url?: string;
  ogImage?: string;
  ogTitle?: string;
}

const Header: React.FC<HeaderProps> = ({
  title = '스위그 - SWYG',
  favIcon = '/assets/img/logo/logo.png',
  description = 'SWYG 에서 어디서도 보지 못한 콘텐츠로 즐거운 시간을 보내고, 직접 만든 콘텐츠로 많은 사용자들을 유입시킬 수 있습니다.',
  keywords = '스위그,swyg,메타콘텐츠,미니게임,유형별테스트,인터랙티브아트,웹사이트,웹콘텐츠,수익창출,개발자,기획자,디자이너,콘텐츠제작자,UX,UI',
  url = 'https://www.swygbro.com',
  ogImage = '/assets/img/logo/ogImage@1x.png',
  ogTitle,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="X_UA_Compatible" content="IE-edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0"
      />
      <link rel="icon" href={favIcon} />

      <link
        rel="apple-touch-icon"
        href="/assets/img/logo/mobile/logo_square_192.png"
      />
      <link
        rel="shortcut icon"
        href="/assets/img/logo/mobile/logo_square_180.png"
      />

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="SWYG" />
      <meta property="og:type" content="website" />

      <meta property="twitter:title" content={ogTitle || title} />
      <meta property="twitter:image" content={ogImage} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:site_name" content="SWYG" />
    </Head>
  );
};

export default Header;
