interface SelectProps {
  name: string;
  value: any;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  placeholder?: string;
  options: () => JSX.Element[];
  className?: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  value,
  onChange,
  placeholder,
  options,
  className,
}) => {
  return (
    <select
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`h-full w-full cursor-pointer bg-transparent focus:outline-none ${className}`}
    >
      <option disabled value="">
        {placeholder}
      </option>
      {options()}
    </select>
  );
};

export default Select;
