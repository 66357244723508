import {
  LogoutIcon,
  PencilAltIcon,
  UserCircleIcon,
  ViewBoardsIcon,
} from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import Image from 'next/image';
import Link from 'next/link';
import { useMutation, useQuery } from 'react-query';
import { logoutApi } from '../../../lib/apis/auth';
import { deactivateUserApi, getMyTeamsApi } from '../../../lib/apis/users';
import { GetMyTeamsResponse } from '../../../lib/apis/users/response';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { logout } from '../../../redux/slices/users/usersSlice';
import { Spinner } from '../../Loader';
import { navigation } from '../NavBar';

interface MobileSideBarProps {
  isMenuOpen: boolean;
  onMenuButtonClick: () => void;
}

const MobileSideBar: React.FC<MobileSideBarProps> = ({
  isMenuOpen,
  onMenuButtonClick,
}) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users);
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  const { data: teamsData } = useQuery<GetMyTeamsResponse[]>(
    ['teams'],
    getMyTeamsApi,
  );
  const { mutate: mutateLogout, isLoading } = useMutation(
    ['logout'],
    logoutApi,
    {
      onError: (e: AxiosError) => axiosErrorHandler(e),
      onSuccess: () => {
        dispatch(logout());
        Cookies.remove('token', {
          domain: isProduction
            ? '.swygbro.com'
            : isPreview
            ? 'swygbro.com'
            : '',
          path: '/',
        });
        onMenuButtonClick();
      },
    },
  );
  const { mutate: mutateDeactivateUser } = useMutation(
    ['deactivateUser'],
    deactivateUserApi,
    {
      onError: (e: AxiosError) => axiosErrorHandler(e),
    },
  );

  const onLogoutButtonClick = () => {
    mutateDeactivateUser();
    mutateLogout();
  };

  return (
    <div
      className={`absolute top-0 left-0 z-50 h-screen w-screen bg-white ${
        isMenuOpen ? 'block opacity-100' : 'sr-only opacity-0'
      } transition duration-300`}
    >
      <div className="relative flex h-16 select-none items-center justify-between border-b p-[6px] md:hidden">
        <button onClick={onMenuButtonClick} className="p-[10px]">
          <XIcon width={24} height={24} />
        </button>
        <Link href="/">
          <a className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-logo text-xl font-bold text-swygBlue-500">
            SWYG
          </a>
        </Link>
        <div>
          {!user.isLoggedIn && (
            <Link href="/auth/login">
              <a className="flex h-11 shrink-0 items-center justify-center pl-[6px] pr-[11px] text-sm">
                로그인
              </a>
            </Link>
          )}
        </div>
      </div>
      <ul className="px-8 py-3">
        {navigation.map((item) => (
          <li key={`phone-nav-item-${item.name}`} className="flex">
            <Link href={`${item.href}`}>
              <a target={item.target} className={`flex flex-1 py-3 text-xl`}>
                {item.name}
              </a>
            </Link>
          </li>
        ))}
      </ul>

      {user.isLoggedIn && (
        <>
          <div className="mx-8 my-2 border-t" />

          <div className="px-8 py-6 text-base text-gray-500">
            <Link href={`/profiles/${user.userId}`}>
              <a className="flex items-center space-x-4">
                <div className="profile-image-container h-12 w-12">
                  <Image
                    src={
                      user.profileImage.length
                        ? user.profileImage
                        : '/assets/img/avatars/guest.png'
                    }
                    alt="profile image"
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <span className="pt-1 text-lg font-extrabold text-gray-600">
                  {user.username}
                </span>
              </a>
            </Link>

            <div className="mt-5 flex flex-col">
              <Link href="/dashboard">
                <a className="flex items-center space-x-4 py-4">
                  <ViewBoardsIcon className="h-6 w-6" />
                  <div>대시보드</div>
                </a>
              </Link>

              {teamsData?.length
                ? teamsData.map((myTeam) => {
                    const { team } = myTeam;
                    const { uniqueId, teamLogo, teamName } = team;

                    return (
                      <Link
                        key={`team-${uniqueId}`}
                        href={`/teams/${uniqueId}`}
                      >
                        <a className="flex items-center space-x-4 py-4">
                          <div className="relative h-6 w-6 rounded-full">
                            <Image
                              alt={`${teamName}-logo`}
                              src={
                                teamLogo.fileLocation
                                  ? teamLogo.fileLocation
                                  : '/assets/img/logo/mobile/logo_square_180.png'
                              }
                              layout="fill"
                              objectFit="cover"
                              className="rounded-full"
                            />
                          </div>
                          <div>{teamName}</div>
                        </a>
                      </Link>
                    );
                  })
                : null}

              <Link href={`/profiles/${user.userId}`}>
                <a className="flex items-center space-x-4 py-4">
                  <UserCircleIcon className="h-6 w-6" />
                  <div>내 프로필</div>
                </a>
              </Link>

              <Link href="/profiles/edit/public">
                <a className="flex items-center space-x-4 py-4">
                  <PencilAltIcon className="h-6 w-6" />
                  <div>프로필 편집</div>
                </a>
              </Link>
            </div>

            <div className="my-2 border-t" />

            <button
              onClick={onLogoutButtonClick}
              className="flex items-center space-x-3 py-6"
            >
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <LogoutIcon
                    width={25}
                    height={25}
                    className="text-gray-500"
                  />
                  <span className="pt-1">로그아웃</span>
                </>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileSideBar;
