import axios from 'axios';
import { HEADER, HOSTING_URL, VERSION } from '../config';
import {
  ConfirmPasswordResetEmailDto,
  DeleteAccountDto,
  LoginDto,
  ResetPasswordDto,
  SendPasswordResetEmailDto,
  SignUpDto,
} from './dto';

//==============================================================
// START Authentication Api
//==============================================================

export const signUpApi = async (data: SignUpDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const deleteAccountApi = async (data: DeleteAccountDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/${userId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
    data,
  });
};

export const loginServerApi = async (data: LoginDto) => {
  // 서버로 로그인 전송
  // local api login route 에서 사용
  const url = `${HOSTING_URL}/${VERSION}/auth/login`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const loginLocalApi = async (data: LoginDto) => {
  // local login api 호출
  // 실제 사용자가 호출하는건 해당 api
  return axios({
    method: 'post',
    url: '/api/auth/login',
    data,
  });
};

export const logoutApi = async () => {
  return axios.post('/api/auth/logout');
};

export const extractJWTApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/auth/jwt`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const getProfileWithJWTApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/auth/user`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

//==============================================================
// END Authentication Api
//==============================================================

//==============================================================
// START Password Reset Api
//==============================================================

export const sendPasswordResetEmailApi = async (
  data: SendPasswordResetEmailDto,
) => {
  const url = `${HOSTING_URL}/${VERSION}/auth/password/email`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const confirmPasswordResetCodeApi = async (
  data: ConfirmPasswordResetEmailDto,
) => {
  const url = `${HOSTING_URL}/${VERSION}/auth/password/confirm`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const resetPasswordApi = async (data: ResetPasswordDto) => {
  const url = `${HOSTING_URL}/${VERSION}/auth/password/reset`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

//==============================================================
// END Password Reset Api
//==============================================================
