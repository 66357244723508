import axios from 'axios';
import { HEADER, HOSTING_URL, VERSION } from '../config';
import {
  DeleteCommentDto,
  GetRepliesDto,
  GetCommentsDto,
  GetContentDto,
  GetContentsDto,
  PostCommentDto,
  PostReplyDto,
  PostCommentReportDto,
  RedirectContentDto,
  ToggleCommentFavDto,
  ToggleContentFavDto,
  UpdateCommentDto,
  ViewContentDto,
  GetContentMembersDto,
  CreateProjectDto,
  GetRecommendedContentsDto,
  GetRecommendTagsDto,
  GetContentsByTagDto,
  GetContentsByWordsDto,
  GetTeamInfoDto,
  GetSaaSProjectTitleDto,
  UpdateSaaSProjectTitleDto,
  GetTaskSummariesDto,
  GetLinksDto,
  DeleteLinkDto,
  GetUnSeenNoteCountDto,
  GetSaaSProjectInfoDto,
  UpdateSaaSProjectInfoDto,
  UpdateProjectImageDto,
  DeleteProjectImageDto,
  GetSaaSProjectMembersDto,
  DeleteSaaSProjectMemberDto,
  GetMyTasksDto,
  CreateLinkDto,
  GetLinkDto,
  UpdateLinkDto,
  UpdateLinkImageDto,
  DeleteLinkImageDto,
  AddSaaSProjectMembersDto,
  UpdateSaaSProjectMemberDto,
  GetNotesDto,
  PostNoteDto,
  DeleteNoteDto,
  GetLastNoteSeenDto,
  UpdateLastNoteSeenDto,
  GetTotalTasksDto,
  CreateProjectTaskDto,
  ViewProjectTaskDto,
  GetProjectTaskDto,
  UpdateProjectTaskDto,
  DeleteProjectTaskDto,
  AddChargeUsersDto,
  DeleteChargeUserDto,
  CreateSubTaskGroupDto,
  UpdateSubTaskGroupDto,
  DeleteSubTaskGroupDto,
  CreateSubTaskDto,
  UpdateSubTaskDto,
  DeleteSubTaskDto,
  CreateTaskNoteDto,
  UpdateTaskNoteDto,
  DeleteTaskNoteDto,
  AddProjectTaskFileDto,
  DeleteProjectTaskFileDto,
  AddTaskLinkDto,
  UpdateTaskLinkDto,
  DeleteTaskLinkDto,
  UpdateTaskLinkImageDto,
  DeleteTaskLinkImageDto,
  GetMyMeetingsDto,
  GetMeetingsForSelectionDto,
  AddMeetingToTaskDto,
  RemoveMeetingFromTaskDto,
  GetProjectMeetingsDto,
  UpdateProjectMeetingDto,
  DeleteProjectMeetingDto,
  CreateProjectMeetingDto,
  GetProjectMeetingDto,
  AddMeetingParticipantsDto,
  RemoveMeetingParticipantDto,
  GetTaskForSelectionDto,
  DeleteMeetingTaskDto,
  UploadEditorImageDto,
} from './dto';

//==============================================================
// START SSG Api
//==============================================================

export const getContentIdsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/ids`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

//==============================================================
// END SSG Api
//==============================================================

//==============================================================
// START Content Api
//==============================================================

export const getMainContentsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/main`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const getContentsApi = async (data: GetContentsDto) => {
  const { limit, orderby, orderbyoption, page } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents?page=${page}&limit=${limit}&orderby=${orderby}&orderbyoption=${orderbyoption}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getContentsOrderbyPopularityRecentApi = async (
  data: GetContentsDto,
) => {
  const { page, limit, orderby, orderbyoption } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/popularity/recent?page=${page}&limit=${limit}&orderby=${orderby}&orderbyoption=${orderbyoption}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getContentsOrderbyPopularityAllTimeApi = async (
  data: GetContentsDto,
) => {
  const { page, limit, orderby, orderbyoption } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/popularity/alltime?page=${page}&limit=${limit}&orderby=${orderby}&orderbyoption=${orderbyoption}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getContentApi = async (data: GetContentDto) => {
  const { contentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/contents/${contentId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const toggleContentFavApi = async (data: ToggleContentFavDto) => {
  const { contentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/togglefav/${contentId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
  });
};

export const viewContentApi = async (data: ViewContentDto) => {
  const { contentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/counts/view/${contentId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
  });
};

export const redirectContentApi = async (data: RedirectContentDto) => {
  const { contentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/counts/redirect/${contentId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
  });
};

export const getContentMembersApi = async (data: GetContentMembersDto) => {
  const { contentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/contents/${contentId}/members`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getRecommendedContentsApi = async (
  data: GetRecommendedContentsDto,
) => {
  const { projectId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/recommended/${projectId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getRecommendTagsApi = async (data: GetRecommendTagsDto) => {
  const { tagName } = data;
  const url = `${HOSTING_URL}/${VERSION}/tags/recommend/${tagName}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getContentsByTagApi = async (data: GetContentsByTagDto) => {
  const { tag, limit, page, orderby, orderbyOption } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/search/tags/${tag}?limit=${limit}&page=${page}&orderby=${orderby}&orderbyOption=${orderbyOption}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getContentsByWordsApi = async (data: GetContentsByWordsDto) => {
  const { word, limit, page, orderby, orderbyOption } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/contents/search/words/${word}?limit=${limit}&page=${page}&orderby=${orderby}&orderbyOption=${orderbyOption}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

//==============================================================
// END Content Api
//==============================================================

//==============================================================
// START Project Api
//==============================================================

export const createProjectApi = async (data: CreateProjectDto) => {
  const url = `${HOSTING_URL}/${VERSION}/projects`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

//==============================================================
// END Project Api
//==============================================================

//==============================================================
// START SaaS Api
//==============================================================

// Shared
//==============================================================

export const getTeamInfoApi = async (data: GetTeamInfoDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/teams`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getSaaSProjectTitleApi = async (data: GetSaaSProjectTitleDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/title`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateSaaSProjectTitleApi = async (
  data: UpdateSaaSProjectTitleDto,
) => {
  const { siteUrl, ...titleData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/title`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: titleData,
  });
};

export const getTaskSummariesApi = async (data: GetTaskSummariesDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/summaries`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getLinksApi = async (data: GetLinksDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/links`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const deleteLinkApi = async (data: DeleteLinkDto) => {
  const { siteUrl, linkId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/links/${linkId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const getUnSeenNoteCountApi = async (data: GetUnSeenNoteCountDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes/unseen`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Dashboard
//==============================================================

export const getSaaSProjectInfoApi = async (data: GetSaaSProjectInfoDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/info`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateSaaSProjectInfoApi = async (
  data: UpdateSaaSProjectInfoDto,
) => {
  const { siteUrl, ...infoData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/info`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: infoData,
  });
};

export const updateProjectImageApi = async (data: UpdateProjectImageDto) => {
  const { siteUrl, file } = data;
  const formData = new FormData();
  formData.append('file', file);
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/images`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const deleteProjectImageApi = async (data: DeleteProjectImageDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/images`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const getSaaSProjectMembersApi = async (
  data: GetSaaSProjectMembersDto,
) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/members`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const deleteSaaSProjectMemberApi = async (
  data: DeleteSaaSProjectMemberDto,
) => {
  const { siteUrl, ...membersData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/members`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
    data: membersData,
  });
};

export const getMyTasksApi = async (data: GetMyTasksDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/me`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getMyMeetingsApi = async (data: GetMyMeetingsDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/me`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Modal - Dashboard
//==============================================================

export const createLinkApi = async (data: CreateLinkDto) => {
  const { domainName, siteName, siteUrl, description, file } = data;
  const formData = new FormData();
  formData.append('siteName', siteName);
  formData.append('siteUrl', siteUrl);
  formData.append('description', description);
  if (file) {
    formData.append('file', file);
  }
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${domainName}/links`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const getPreservedLinkLogosApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/projects/links/preserved`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getLinkApi = async (data: GetLinkDto) => {
  const { siteUrl, linkId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/links/${linkId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateLinkApi = async (data: UpdateLinkDto) => {
  const { domainName, linkId, ...linkData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${domainName}/links/${linkId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: linkData,
  });
};

export const updateLinkImageApi = async (data: UpdateLinkImageDto) => {
  const { siteUrl, linkId, file } = data;
  const formData = new FormData();
  formData.append('file', file);
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/links/${linkId}/images`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const deleteLinkImageApi = async (data: DeleteLinkImageDto) => {
  const { siteUrl, linkId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/links/${linkId}/images`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const addSaaSProjectMembersApi = async (
  data: AddSaaSProjectMembersDto,
) => {
  const { siteUrl, ...membersData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/members`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: membersData.members,
  });
};

export const updateSaaSProjectMemberApi = async (
  data: UpdateSaaSProjectMemberDto,
) => {
  const { siteUrl, ...memberData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/members`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: memberData,
  });
};

export const getNotesApi = async (data: GetNotesDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const postNoteApi = async (data: PostNoteDto) => {
  const { siteUrl, ...noteData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: noteData,
  });
};

export const deleteNoteApi = async (data: DeleteNoteDto) => {
  const { siteUrl, noteId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes/${noteId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const getLastNoteSeenApi = async (data: GetLastNoteSeenDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes/last-seen`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateLastNoteSeenApi = async (data: UpdateLastNoteSeenDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/notes/last-seen`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

// Tasks
//==============================================================

export const getTotalTasksApi = async (data: GetTotalTasksDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const createProjectTaskApi = async (data: CreateProjectTaskDto) => {
  const { siteUrl, ...taskData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: taskData,
  });
};

// Modal - Tasks
//==============================================================

export const viewProjectTaskApi = async (data: ViewProjectTaskDto) => {
  const { siteUrl, taskId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/view`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

export const getProjectTaskApi = async (data: GetProjectTaskDto) => {
  const { siteUrl, taskId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateProjectTaskApi = async (data: UpdateProjectTaskDto) => {
  const { siteUrl, taskId, ...taskData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: taskData,
  });
};

export const deleteProjectTaskApi = async (data: DeleteProjectTaskDto) => {
  const { siteUrl, taskId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const addChargeUsersApi = async (data: AddChargeUsersDto) => {
  const { siteUrl, taskId, ...chargeUsersData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/users`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: chargeUsersData,
  });
};

export const deleteChargeUserApi = async (data: DeleteChargeUserDto) => {
  const { siteUrl, taskId, ...chargeUserData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/users`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
    data: chargeUserData,
  });
};

export const createSubTaskGroupApi = async (data: CreateSubTaskGroupDto) => {
  const { siteUrl, taskId, ...subTaskGroupData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtaskgroups`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: subTaskGroupData,
  });
};

export const updateSubTaskGroupApi = async (data: UpdateSubTaskGroupDto) => {
  const { siteUrl, taskId, subTaskGroupId, ...subTaskGroupData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtaskgroups/${subTaskGroupId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: subTaskGroupData,
  });
};

export const deleteSubTaskGroupApi = async (data: DeleteSubTaskGroupDto) => {
  const { siteUrl, taskId, subTaskGroupId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtaskgroups/${subTaskGroupId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const createSubTaskApi = async (data: CreateSubTaskDto) => {
  const { siteUrl, taskId, ...subTaskData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtasks`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: subTaskData,
  });
};

export const updateSubTaskApi = async (data: UpdateSubTaskDto) => {
  const { siteUrl, taskId, subTaskGroupId, subTaskId, ...subTaskData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtasks/${subTaskId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: subTaskData,
  });
};

export const deleteSubTaskApi = async (data: DeleteSubTaskDto) => {
  const { siteUrl, taskId, subTaskId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/subtasks/${subTaskId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const createTaskNoteApi = async (data: CreateTaskNoteDto) => {
  const { siteUrl, taskId, ...noteData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/notes`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: noteData,
  });
};

export const updateTaskNoteApi = async (data: UpdateTaskNoteDto) => {
  const { siteUrl, taskId, noteId, ...noteData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/notes/${noteId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: noteData,
  });
};

export const deleteTaskNoteApi = async (data: DeleteTaskNoteDto) => {
  const { siteUrl, taskId, noteId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/notes/${noteId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const addProjectTaskFileApi = async (data: AddProjectTaskFileDto) => {
  const { siteUrl, taskId, file } = data;
  const formData = new FormData();
  formData.append('file', file);
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/files`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const deleteProjectTaskFileApi = async (
  data: DeleteProjectTaskFileDto,
) => {
  const { siteUrl, taskId, fileId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/files/${fileId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const addTaskLinkApi = async (data: AddTaskLinkDto) => {
  const { domainName, taskId, siteName, siteUrl, description, file } = data;
  const formData = new FormData();
  formData.append('siteName', siteName);
  formData.append('siteUrl', siteUrl);
  formData.append('description', description);
  if (file) {
    formData.append('file', file);
  }
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${domainName}/tasks/${taskId}/links`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const updateTaskLinkApi = async (data: UpdateTaskLinkDto) => {
  const { domainName, taskId, linkId, ...linkData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${domainName}/tasks/${taskId}/links/${linkId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: linkData,
  });
};

export const deleteTaskLinkApi = async (data: DeleteTaskLinkDto) => {
  const { siteUrl, taskId, linkId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/links/${linkId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const updateTaskLinkImageApi = async (data: UpdateTaskLinkImageDto) => {
  const { siteUrl, taskId, linkId, file } = data;
  const formData = new FormData();
  formData.append('file', file);
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/links/${linkId}/images`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

export const deleteTaskLinkImageApi = async (data: DeleteTaskLinkImageDto) => {
  const { siteUrl, taskId, linkId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/links/${linkId}/images`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const getMeetingsForSelectionApi = async (
  data: GetMeetingsForSelectionDto,
) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/selections/meetings`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const addMeetingToTaskApi = async (data: AddMeetingToTaskDto) => {
  const { siteUrl, taskId, ...meetingData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/meetings`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: meetingData,
  });
};

export const removeMeetingFromTaskApi = async (
  data: RemoveMeetingFromTaskDto,
) => {
  const { siteUrl, taskId, ...meetingData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/tasks/${taskId}/meetings`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
    data: meetingData,
  });
};

export const uploadEditorImageApi = async (data: UploadEditorImageDto) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('file', file);
  const url = `${HOSTING_URL}/${VERSION}/uploads/editors`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: formData,
  });
};

// Meetings
//==============================================================

export const getProjectMeetingsApi = async (data: GetProjectMeetingsDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateProjectMeetingApi = async (
  data: UpdateProjectMeetingDto,
) => {
  const { siteUrl, meetingId, ...meetingData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: meetingData,
  });
};

export const deleteProjectMeetingApi = async (
  data: DeleteProjectMeetingDto,
) => {
  const { siteUrl, meetingId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

// Modal - Meetings
//==============================================================

export const createProjectMeetingApi = async (
  data: CreateProjectMeetingDto,
) => {
  const { siteUrl, ...meetingData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: meetingData,
  });
};

export const getProjectMeetingApi = async (data: GetProjectMeetingDto) => {
  const { siteUrl, meetingId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const addMeetingParticipantsApi = async (
  data: AddMeetingParticipantsDto,
) => {
  const { siteUrl, meetingId, ...participantsData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}/participants`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: participantsData,
  });
};

export const removeMeetingParticipantApi = async (
  data: RemoveMeetingParticipantDto,
) => {
  const { siteUrl, meetingId, ...participantData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}/participants`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
    data: participantData,
  });
};

export const getTaskForSelectionApi = async (data: GetTaskForSelectionDto) => {
  const { siteUrl } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/selections/tasks`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const deleteMeetingTaskApi = async (data: DeleteMeetingTaskDto) => {
  const { siteUrl, meetingId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/saas/${siteUrl}/meetings/${meetingId}/tasks`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

//==============================================================
// END SaaS Api
//==============================================================

//==============================================================
// START Comment Api
//==============================================================

export const postCommentApi = async (data: PostCommentDto) => {
  const { projectId, ...commentData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/${projectId}/comments`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: commentData,
  });
};

export const getCommentsApi = async (data: GetCommentsDto) => {
  const { projectId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/${projectId}/comments`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateCommentApi = async (data: UpdateCommentDto) => {
  const { commentId, ...commentData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/${commentId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: commentData,
  });
};

export const deleteCommentApi = async (data: DeleteCommentDto) => {
  const { commentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/${commentId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

export const postReplyApi = async (data: PostReplyDto) => {
  const { commentId, ...replyData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/${commentId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: replyData,
  });
};

export const getRepliesApi = async (data: GetRepliesDto) => {
  const { commentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/${commentId}/replies`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const toggleCommentFavApi = async (data: ToggleCommentFavDto) => {
  const { commentId } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/togglefav/${commentId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
  });
};

export const postCommentReportApi = async (data: PostCommentReportDto) => {
  const { commentId, ...commentReportData } = data;
  const url = `${HOSTING_URL}/${VERSION}/projects/comments/${commentId}/reports`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data: commentReportData,
  });
};

//==============================================================
// End Comment Api
//==============================================================
