import {
  DotsVerticalIcon,
  HeartIcon as HeartOutlineIcon,
  PencilAltIcon,
  SpeakerphoneIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/solid';
import { AxiosError } from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteCommentApi,
  getRepliesApi,
  postReplyApi,
  toggleCommentFavApi,
  updateCommentApi,
} from '../../../lib/apis/projects';
import { PostReplyDto } from '../../../lib/apis/projects/dto';
import { useClickOutside } from '../../../lib/customHooks';
import { axiosErrorHandler } from '../../../lib/functions/handler';
import { elapsedTime } from '../../../lib/functions/utils';
import { Comment, Member } from '../../../lib/types/project';
import { useAppSelector } from '../../../redux/app/hooks';
import { CommentInput } from '../../Input';
import ReplyContainer from '../ReplyContainer';

interface CommentContainerProps {
  members: Member[];
  comment: Comment;
  imageUrl?: string;
  setIsReportShown: Dispatch<SetStateAction<boolean>>;
  setReportedCommentId: Dispatch<SetStateAction<number>>;
  setReportedUserId: Dispatch<SetStateAction<string>>;
}

const CommentContainer: React.FC<CommentContainerProps> = ({
  members,
  comment,
  imageUrl = '',
  setIsReportShown,
  setReportedCommentId,
  setReportedUserId,
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.users);
  const [updatedReplyData, setUpdatedReplyData] = useState<Comment[]>();
  const [favCount, setFavCount] = useState(0);
  const [isFav, setIsFav] = useState(false);
  const [isReplyShown, setIsReplyShown] = useState(false);
  const [isReplyInputShown, setIsReplyInputShown] = useState(false);
  const [isCommentEditMode, setIsCommentEditMode] = useState(false);
  const [reply, setReply] = useState('');
  const [editContext, setEditContext] = useState('');
  const { isVisible, ref, setIsVisible } = useClickOutside(false);
  const { data: replyData, refetch: refetchReplyData } = useQuery<Comment[]>(
    ['reply', comment.id],
    () => getRepliesApi({ commentId: comment.id }),
    {
      enabled: false,
      onSuccess: (data) => {
        setUpdatedReplyData(data);
      },
    },
  );
  const { mutate: mutateToggleCommentFav } = useMutation(
    ['toggleCommentFav'],
    toggleCommentFavApi,
    {
      onSuccess: () => {
        isFav
          ? setFavCount((prevState) => prevState - 1)
          : setFavCount((prevState) => prevState + 1);
        setIsFav((prevState) => !prevState);
      },
      onError: (e: AxiosError) => {
        axiosErrorHandler(e);
        router.push('/auth/login');
      },
    },
  );
  const { mutate: mutatePostReply } = useMutation(['postReply'], postReplyApi, {
    onSuccess: (newReply) => {
      queryClient.invalidateQueries('comment');
      if (comment.childCommentsCount) {
        replyData?.push(newReply.data);
        setUpdatedReplyData(replyData);
      } else {
        refetchReplyData();
      }
    },
    onError: (e: AxiosError) => axiosErrorHandler(e),
  });
  const { mutate: mutateUpdateComment } = useMutation(
    ['updateComment', comment.id],
    () => updateCommentApi({ commentId: comment.id, context: editContext }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('comment');
      },
    },
  );
  const { mutate: mutateDeleteComment } = useMutation(
    ['deleteComment', comment.id],
    () => deleteCommentApi({ commentId: comment.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('comment');
        alert('삭제가 완료되었습니다.');
      },
    },
  );

  useEffect(() => {
    if (comment) {
      const { favUsers } = comment;
      if (user.isLoggedIn) {
        const favUsersId = favUsers.map((user) => user.id);
        if (favUsersId.includes(user.userId)) {
          setIsFav(true);
        }
      }
      setFavCount(favUsers.length);
    }
  }, [comment, user, setIsFav, setFavCount]);

  const onReplyShownButtonClick = () => {
    if (isReplyShown) {
      setIsReplyShown(false);
    } else {
      setIsReplyShown(true);
      refetchReplyData();
    }
  };

  const onReplyInputShownButtonClick = () => {
    setIsReplyInputShown((prevState) => !prevState);
  };

  const onDotsButtonClick = () => {
    setIsVisible((prevState) => !prevState);
  };

  const onEditButtonClick = (context: string) => {
    setIsVisible(false);
    setIsCommentEditMode(true);
    setEditContext(context);
  };

  const onSaveButtonClick = () => {
    mutateUpdateComment();
    if (isCommentEditMode) {
      setIsCommentEditMode(false);
    }
  };

  const onDeleteButtonClick = () => {
    const confirm = window.confirm('댓글을 삭제하시겠습니까?');
    if (confirm) {
      mutateDeleteComment();
    }
  };

  const onReportButtonClick = () => {
    setReportedCommentId(comment.id);
    setReportedUserId(comment.user.id);
    setIsReportShown(true);
  };

  const onPostReplyButtonClick = () => {
    const data: PostReplyDto = {
      commentId: comment.id,
      context: reply,
    };
    mutatePostReply(data);
    setReply('');
    setIsReplyShown(true);
  };

  return (
    <>
      <div className="group flex space-x-3 text-xs">
        {!comment.isRemoved ? (
          <Link href={`/profiles/${comment.user.id}`}>
            <a className="profile-image-container h-10 w-10 flex-shrink-0 bg-gray-100 hover:shadow-profileContainer">
              <Image
                src={imageUrl}
                alt={`${comment.user.username} Profile Image`}
                layout="fill"
                objectFit="cover"
                priority
              />
            </a>
          </Link>
        ) : (
          <div className="profile-image-container h-10 w-10 flex-shrink-0 bg-gray-100 hover:shadow-profileContainer">
            <Image
              src="/assets/img/avatars/guest.png"
              alt="guest Profile Image"
              layout="fill"
              objectFit="cover"
              priority
            />
          </div>
        )}

        <div className="flex w-full flex-col items-start">
          <div className="flex w-full flex-col">
            {!comment.isRemoved ? (
              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <div className="space-x-1 font-semibold">
                    <span className="text-xs">
                      {comment.user.role === 'planner' ? '기획' : '개발'}
                    </span>
                    <Link href={`/profiles/${comment.user.id}`}>
                      <a className="text-base hover:opacity-70">
                        {comment.user.username}
                      </a>
                    </Link>
                  </div>
                  {members.find(
                    (member) => comment.user.id === member.user.id,
                  ) && (
                    <div className="flex h-[18px] w-[37px] items-center justify-center rounded-base border border-swygBlue-500 text-[10px] text-swygBlue-500">
                      제작자
                    </div>
                  )}
                  <div className="text-gray-600">
                    {elapsedTime(comment.updatedAt)}
                  </div>
                </div>

                {!isCommentEditMode ? (
                  <pre className="whitespace-pre-wrap font-kr text-xs leading-8 text-gray-900">
                    {comment.context}
                  </pre>
                ) : (
                  <CommentInput
                    type="comment"
                    isEditMode
                    prevValue={comment.context}
                    value={editContext}
                    onChange={setEditContext}
                    onSaveButtonClick={onSaveButtonClick}
                    onCancelButtonClick={() => setIsCommentEditMode(false)}
                  />
                )}
              </div>
            ) : (
              <div className="space-y-3 pl-2">
                <div className="text-lg font-extrabold">(알수없음)</div>
                <pre className="font-kr text-xs leading-8 text-gray-900">
                  삭제된 댓글입니다.
                </pre>
              </div>
            )}

            <div className="relative flex w-full items-center justify-between">
              <div className="flex items-center space-x-[2px]">
                <button
                  onClick={() =>
                    mutateToggleCommentFav({ commentId: comment.id })
                  }
                  className="-ml-2 px-2 py-[6px]"
                >
                  <div className="flex items-center space-x-1">
                    {isFav ? (
                      <HeartSolidIcon
                        width={24}
                        height={24}
                        className="text-rose-400"
                      />
                    ) : (
                      <HeartOutlineIcon width={24} height={24} />
                    )}
                    <span className="text-sm font-semibold">{favCount}</span>
                  </div>
                </button>
                {comment.childCommentsCount ? (
                  <div className="flex space-x-[7px]">
                    <button
                      onClick={onReplyShownButtonClick}
                      className="flex items-center space-x-1 px-[10px] py-2 text-xs font-semibold text-swygBlue-600 hover:text-swygBlueButton-hover active:text-swygBlueButton-pressed"
                    >
                      <div>{`답글 ${comment.childCommentsCount}개`}</div>
                      <div>{`${isReplyShown ? '▲' : '▼'}`}</div>
                    </button>
                    <button
                      onClick={onReplyInputShownButtonClick}
                      className="flex items-center space-x-1 px-2 py-[6px] text-xs font-semibold text-gray-500 hover:rounded-base hover:bg-gray-100"
                    >
                      답글
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={onReplyInputShownButtonClick}
                    className="flex items-center space-x-1 px-2 py-[6px] text-xs font-semibold text-gray-500 hover:rounded-base hover:bg-gray-100"
                  >
                    답글
                  </button>
                )}
              </div>

              {!comment.isRemoved && (
                <>
                  <button
                    onClick={onDotsButtonClick}
                    className="-m-2 rounded-full p-2 hover:bg-gray-100 md:hidden md:group-hover:block"
                  >
                    <DotsVerticalIcon width={24} height={24} />
                  </button>
                  <div
                    ref={ref}
                    className={`${isVisible ? '' : 'hidden'} ${
                      comment.user.id === user.userId
                        ? '-bottom-[63px]'
                        : '-bottom-[38px]'
                    } absolute right-0 flex items-center justify-center rounded-base border bg-white py-[6px] text-[10px] font-semibold text-gray-500`}
                  >
                    {comment.user.id === user.userId ? (
                      <div className="flex flex-col space-y-[1px] text-center">
                        <button
                          onClick={() => onEditButtonClick(comment.context)}
                          className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                        >
                          <PencilAltIcon width={20} height={20} />
                          <div>수정</div>
                        </button>
                        <button
                          onClick={onDeleteButtonClick}
                          className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                        >
                          <TrashIcon width={20} height={20} />
                          <div>삭제</div>
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={onReportButtonClick}
                        className="flex items-center justify-between space-x-1 px-[6px] py-[2px] hover:bg-gray-100"
                      >
                        <SpeakerphoneIcon width={20} height={20} />
                        <div>신고</div>
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Reply Input */}
          <div
            className={`mt-[22px] ${isReplyShown && 'mb-[26px]'} w-full ${
              !isReplyInputShown && 'hidden'
            }`}
          >
            <CommentInput
              type="reply"
              value={reply}
              onChange={setReply}
              onSaveButtonClick={onPostReplyButtonClick}
            />
          </div>

          {/* Reply */}
          {comment.childCommentsCount || updatedReplyData?.length ? (
            <div
              className={`${isReplyShown ? '' : 'hidden'} ${
                !isReplyInputShown && 'mt-[30px]'
              } w-full space-y-6`}
            >
              {updatedReplyData?.map((reply) => (
                <ReplyContainer
                  key={reply.id}
                  replyData={updatedReplyData}
                  reply={reply}
                  members={members}
                  setIsReportShown={setIsReportShown}
                  setReportedCommentId={setReportedCommentId}
                  setReportedUserId={setReportedUserId}
                  setUpdatedReplyData={setUpdatedReplyData}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CommentContainer;
