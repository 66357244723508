import { combineReducers } from 'redux';
import { contentsSlice } from './contents/contentsSlice';
import { createProjectSlice } from './projects/createProjectSlice';
import { createTeamProjectSlice } from './teams/createTeamProjectSlice';
import { usersSlice } from './users/usersSlice';

const rootReducer = combineReducers({
  [contentsSlice.name]: contentsSlice.reducer,
  [createProjectSlice.name]: createProjectSlice.reducer,
  [createTeamProjectSlice.name]: createTeamProjectSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
});

export default rootReducer;
