import { differenceInCalendarDays } from 'date-fns';
import { commaRegEx } from '../regex';

/**
 *
 * @param num number, string type 둘다 가능
 * @returns 3자리 수마다 comma
 */
export const addComma = (num: number | string) => {
  return num.toString().replace(commaRegEx, ',');
};

/**
 *
 * @param bytes 파일 byte number type
 * @param decimals 뒤에 몇자리까지
 * @returns 각 단위에 맞게 변환
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 *
 * @param str 변환할 string
 * @returns 첫 번째 문자를 대문자로 변경
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *
 * @param dueDate 목표 일자
 * @returns 남은 일 수
 */
export const calculateLeftDate = (dueDate: Date) => {
  const currentDate = new Date();
  const leftDate = differenceInCalendarDays(new Date(dueDate), currentDate);
  return leftDate.toString();
  // const leftTime = dueDate.getTime() - currentDate.getTime();
  // const leftDate = Math.floor(leftTime / 1000 / 60 / 60 / 24) + 1;
  // return leftDate.toString();
};

/**
 *
 * @param loginDate 로그인 일자
 * @returns 로그인 경과시간
 */
export const elapsedTime = (loginDate: Date) => {
  const loginTime = new Date(loginDate);
  const currentTime = new Date();
  const diff = currentTime.getTime() - loginTime.getTime();
  const times = [
    { time: '년', ms: 1000 * 60 * 60 * 24 * 365 },
    { time: '개월', ms: 1000 * 60 * 60 * 24 * 30 },
    { time: '일', ms: 1000 * 60 * 60 * 24 },
    { time: '시간', ms: 1000 * 60 * 60 },
    { time: '분', ms: 1000 * 60 },
  ];
  for (const value of times) {
    const betweenTime = Math.floor(diff / value.ms);
    if (betweenTime > 0) {
      return `${betweenTime}${value.time} 전`;
    }
  }
  return '방금 전';
};
