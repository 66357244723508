import { AdjustmentsIcon } from '@heroicons/react/outline';
import { Dispatch, SetStateAction, useState } from 'react';
import { SearchResultOrderby } from '../../../lib/apis/projects/dto';

interface SortContainerProps {
  setIsFilterOpen: Dispatch<SetStateAction<boolean>>;
  orderby: SearchResultOrderby;
  setOrderby: Dispatch<SetStateAction<SearchResultOrderby>>;
}

const SortContainer: React.FC<SortContainerProps> = ({
  setIsFilterOpen,
  orderby,
  setOrderby,
}) => {
  const [isOrderbyPopularity, setIsOrderbyPopularity] = useState(true);

  const onFilterButtonClick = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const onPopularityButtonClick = () => {
    setOrderby('weeklySession');
    setIsOrderbyPopularity(true);
  };

  const onWeeklyButtonClick = () => {
    setOrderby('weeklySession');
  };

  const onTotalButtonClick = () => {
    setOrderby('totalSession');
  };

  const onRecentButtonClick = () => {
    setOrderby('onBoardDate');
    setIsOrderbyPopularity(false);
  };

  return (
    <div className="flex flex-col space-y-5 pl-[10px]">
      <button
        onClick={onFilterButtonClick}
        className="-mx-[10px] flex w-fit items-center space-x-2 rounded-lg px-[10px] py-2 text-base font-semibold text-gray-500 hover:bg-gray-100"
      >
        <AdjustmentsIcon width={24} height={24} className="rotate-90" />
        <div>정렬</div>
      </button>
      <div className="flex h-[86.5px] items-start space-x-[23px] text-xs text-gray-500">
        <div className="flex flex-col space-y-3">
          <button
            onClick={onPopularityButtonClick}
            className={`${
              isOrderbyPopularity ? 'border-b-2 border-swygBlue-500' : ''
            } p-1 hover:border-b-2 hover:border-swygBlue-500`}
          >
            인기순
          </button>
          {isOrderbyPopularity && (
            <>
              <button
                onClick={onWeeklyButtonClick}
                className={`${
                  orderby === ('weeklySession' as SearchResultOrderby)
                    ? 'font-semibold text-gray-600'
                    : ''
                } hover:text-gray-700`}
              >
                최근
              </button>
              <button
                onClick={onTotalButtonClick}
                className={`${
                  orderby === ('totalSession' as SearchResultOrderby)
                    ? 'font-semibold text-gray-600'
                    : ''
                } hover:text-gray-700`}
              >
                전체
              </button>
            </>
          )}
        </div>
        <button
          onClick={onRecentButtonClick}
          className={`${
            !isOrderbyPopularity ? 'border-b-2 border-swygBlue-500' : ''
          } p-1 hover:border-b-2 hover:border-swygBlue-500`}
        >
          최신순
        </button>
      </div>
    </div>
  );
};

export default SortContainer;
