import { Dispatch, SetStateAction } from 'react';

interface ImagesInputProps {
  // input id 로 사용
  // 따라서 button click handler 에서 id 값 클릭해야 함
  inputId: string;
  setFiles: Dispatch<SetStateAction<File[]>>;
  setImageUrls: Dispatch<
    SetStateAction<(string | ArrayBuffer | null | undefined)[]>
  >;
}

const ImagesInput: React.FC<ImagesInputProps> = ({
  inputId,
  setFiles,
  setImageUrls,
}) => {
  const onFileInputClickHandler: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const [fileType, _] = files[i].type.split('/');
        if (fileType !== 'image') {
          alert('이미지 파일만 업로드 할 수 있습니다.');
        } else {
          const fileReader = new FileReader();
          fileReader.onload = (e) => {
            setImageUrls((prevState) => [...prevState, e.target?.result]);
          };
          fileReader.readAsDataURL(files[i]);
          setFiles((prevState) => [...prevState, files[i]]);
        }
      }
    }
  };

  return (
    <input
      accept="image/*"
      id={inputId}
      className="sr-only"
      name="files-upload"
      type="file"
      multiple={true}
      onChange={onFileInputClickHandler}
    />
  );
};

export default ImagesInput;
