interface TextAreaProps {
  ref?: React.LegacyRef<HTMLTextAreaElement> | undefined;
  id?: string;
  name: string;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  maxLength?: number;
  rows?: number;
  isResizable?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

const TextArea: React.FC<TextAreaProps> = ({
  ref,
  id,
  name,
  value,
  onChange,
  placeholder = '',
  autoComplete = 'off',
  autoFocus = false,
  className,
  maxLength = undefined,
  rows,
  isResizable = false,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyPress,
}) => {
  return (
    <textarea
      ref={ref}
      id={id}
      name={name}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      className={`h-full w-full bg-transparent focus:outline-none ${className} ${
        isResizable ? 'resize-y' : 'resize-none scrollbar-hide'
      }`}
      maxLength={maxLength}
      rows={rows}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
    />
  );
};

export default TextArea;
