import { createSlice } from '@reduxjs/toolkit';
import {
  ContentsOrderby,
  ContentsPeriodOption,
} from '../../../lib/apis/projects/dto';
import { Update_Orderby_Actions, Update_Period_Actions } from './types';

export interface ContentsState {
  orderby: ContentsOrderby;
  period: ContentsPeriodOption;
}

const initialState: ContentsState = {
  orderby: 'redirectCount',
  period: 'recent',
};

export const contentsSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    updateOrderby: (state, action: Update_Orderby_Actions) => {
      state.orderby = action.payload.orderby;
    },
    updatePeriod: (state, action: Update_Period_Actions) => {
      state.period = action.payload.period;
    },
  },
});

export const { updateOrderby, updatePeriod } = contentsSlice.actions;

export default contentsSlice.reducer;
