import * as Sentry from '@sentry/nextjs';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import 'tippy.js/dist/tippy.css';
import App from '../components/App';
import ErrorBoundary from '../components/ErrorBoundary';
import { store } from '../redux/app/store';
import '../styles/globals.css';

axios.defaults.withCredentials = true;

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      environment: 'production',
      dsn: 'https://425ae83f57b94a56a0c70f80fc220ef3@o932951.ingest.sentry.io/5881999',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.7,
    });
    mixpanel.init('07ef93c653dc558c7df1581f8d03e9c8', { debug: false });
  } else {
    mixpanel.init('07ef93c653dc558c7df1581f8d03e9c8', { debug: true });
  }

  mixpanel.track('사이트 방문');

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <Provider store={store}>
          <ErrorBoundary>
            <App>
              <Component {...pageProps} />
              <Analytics />
            </App>
          </ErrorBoundary>
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default MyApp;
