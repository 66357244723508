import { useEffect, useRef, useState } from 'react';
import Footer from '../../Footer';
import Header, { HeaderProps } from '../../Header';
import NavBar from '../../NavBar';

interface MainContainerProps {
  header?: HeaderProps;
}

const MainContainer: React.FC<MainContainerProps> = ({ children, header }) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const height = footerRef.current?.clientHeight;
    if (height) {
      setFooterHeight(height);
    }
  }, [footerRef]);

  return (
    <div>
      <Header
        title={header?.title}
        favIcon={header?.favIcon}
        description={header?.description}
        keywords={header?.keywords}
        url={header?.url}
        ogImage={header?.ogImage}
        ogTitle={header?.ogTitle}
      />
      <div
        style={{
          minHeight: `calc(100vh - ${footerHeight}px)`,
          paddingBottom: '40px',
        }}
        className="text-xs"
      >
        <NavBar />
        <div className="py-16 md:py-20">{children}</div>
      </div>
      <Footer ref={footerRef} />
    </div>
  );
};

export default MainContainer;
