//==============================================================
// START User Enums
//==============================================================

export enum UserRole {
  GUEST = 'guest',
  DEVELOPER = 'developer',
  PLANNER = 'planner',
  DESIGNER = 'designer',
}

export const UserRoleName = new Map([
  ['guest', '선택 안 함'],
  ['developer', '개발자'],
  ['planner', '기획자'],
  ['designer', '디자이너'],
]);

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  NONE = 'none',
}

export enum LoginType {
  EMAIL = 'email',
  KAKAO = 'kakao',
  NAVER = 'naver',
  GITHUB = 'github',
  GOOGLE = 'google',
}

export enum ExpertiseLevel {
  INTERESTED = 'interested',
  LOW = 'low',
  MIDDLE = 'middle',
  HIGH = 'high',
  EXPERT = 'expert',
}

export enum MemberDepartment {
  DESIGNER = 'designer',
  PLANNER = 'planner',
  DEVELOPER = 'developer',
}

export const MemberDepartmentName = new Map([
  [MemberDepartment.DESIGNER, '디자이너'],
  [MemberDepartment.PLANNER, '기획자'],
  [MemberDepartment.DEVELOPER, '개발자'],
]);

export enum ProjectRole {
  ADMIN = 'admin',
  MANAGER = 'manager',
  USER = 'user',
}

export enum MemberRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  MEMBER = 'member',
}

export const MemberRoleName = new Map([
  [MemberRole.OWNER, '소유자'],
  [MemberRole.MANAGER, '매니저'],
  [MemberRole.MEMBER, '멤버'],
]);

export enum SearchType {
  TAG = 'tag',
  WORD = 'word',
}

//==============================================================
// END User Enums
//==============================================================

//==============================================================
// START Project Enums
//==============================================================

export enum Progress {
  REQUESTING = 'requesting',
  APPROVED = 'approved',
  DENIED = 'denied',
}

export const ProgressName = new Map([
  [Progress.REQUESTING, '처리 중'],
  [Progress.APPROVED, '승인'],
  [Progress.DENIED, '거절'],
]);

export enum ENV_TYPE {
  ENCRYPTED = 'encrypted',
}

export enum ToolCategory {
  none = 'none',
  backend = 'backend',
  frontend = 'frontend',
  utility = 'utility',
  business = 'business',
}

export enum RequestStatus {
  REQUESTING = 'requesting',
  DENIED = 'denied',
  APPROVED = 'approved',
  COMPLETE = 'complete',
}

export const RequestStatusName = new Map([
  [RequestStatus.REQUESTING, '처리 중'],
  [RequestStatus.APPROVED, '승인'],
  [RequestStatus.DENIED, '거절'],
]);

export const RequestStatusTextColor = new Map([
  [RequestStatus.REQUESTING, 'text-gray-400'],
  [RequestStatus.APPROVED, 'text-green-600'],
  [RequestStatus.DENIED, 'text-rose-500'],
]);

export enum DeniedReason {
  BUILD = 'build',
  NOCHANGE = 'nochange',
  ILLEGAL = 'illegal',
  OTHERS = 'others',
}

export const DeniedReasonName = new Map([
  [DeniedReason.BUILD, '빌드 실패'],
  [DeniedReason.NOCHANGE, '변동 사항 없음'],
  [DeniedReason.ILLEGAL, '정책 위반'],
  [DeniedReason.OTHERS, '기타'],
]);

export enum ReportReason {
  SPAM = 'spam', //원치 않는 상업성 콘텐츠 또는 스팸
  DISGUSTING = 'disgusting', //혐오 발언 또는 상징
  ABUSING = 'abusing', //욕설 또는 부적절한 언어
  MISINFORMATION = 'misinformation', //잘못된 정보
  REPEAT = 'repeat', //도배성 댓글
  POLITICAL = 'political', //정치적 논쟁
  DONTLIKE = 'dontLike', //마음에 들지 않음
}

export enum ProjectType {
  service = 'service',
  content = 'content',
  none = 'none',
}

export enum ProgressStatus {
  ONGOING = 'ongoing',
  FINISHED = 'finished',
  REQUESTING = 'requesting',
  DEPLOYED = 'deployed',
  REJECTED = 'rejected',
}

export const ProgressStatusName = new Map([
  [ProgressStatus.ONGOING, '진행 중'],
  [ProgressStatus.FINISHED, '배포 전'],
  [ProgressStatus.REQUESTING, '처리 중'],
  [ProgressStatus.DEPLOYED, '출시'],
  [ProgressStatus.REJECTED, '거절'],
]);

export enum ResponsiveAdType {
  SQUARE = 'square',
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

//==============================================================
// END Project Enums
//==============================================================

//==============================================================
// START Suggestion Enums
//==============================================================

export enum CurrentOption {
  none = 'none',
  solo = 'solo',
  team = 'team',
}

export const CurrentOptionName = new Map([
  [CurrentOption.none, '선택 안함'],
  [CurrentOption.solo, '개인 프로젝트'],
  [CurrentOption.team, '팀 프로젝트'],
]);

export enum CurrentProgress {
  none = 'none',
  idea = 'idea',
  documented_simple = 'documentedSimple',
  documented_specific = 'documentedSpecific',
}

export const CurrentProgressName = new Map([
  [CurrentProgress.none, '선택 안함'],
  [CurrentProgress.idea, '아이디어만 존재'],
  [CurrentProgress.documented_simple, '간단히 정리한 문서'],
  [CurrentProgress.documented_specific, '상세히 정리한 문서'],
]);

//==============================================================
// END Suggestion Enums
//==============================================================

//==============================================================
// START Dashboard Enums
//==============================================================

export enum IconPosition {
  navBar = 'navBar',
  card = 'card',
}

//==============================================================
// END Dashboard Enums
//==============================================================

//==============================================================
// START SaaS Enums
//==============================================================

export enum TaskLabel {
  ALL = 'all',
  PLANNING = 'planning',
  DESIGN = 'design',
  DEVELOPMENT_ALL = 'development_all',
  DEVELOPMENT_CLIENT = 'development_client',
  DEVELOPMENT_SERVER = 'development_server',
}

export const TaskLabelName = new Map([
  [TaskLabel.ALL, '전체'],
  [TaskLabel.PLANNING, '기획'],
  [TaskLabel.DESIGN, '디자인'],
  [TaskLabel.DEVELOPMENT_ALL, '개발'],
  [TaskLabel.DEVELOPMENT_CLIENT, '클라이언트'],
  [TaskLabel.DEVELOPMENT_SERVER, '서버'],
]);

export enum TaskCurrentStatus {
  UPCOMING = 'upcoming',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
  DELAYED = 'delayed',
}

export const TaskCurrentStatusName = new Map([
  [TaskCurrentStatus.UPCOMING, '진행 전'],
  [TaskCurrentStatus.ONGOING, '진행 중'],
  [TaskCurrentStatus.FINISHED, '완료'],
  [TaskCurrentStatus.DELAYED, '지연'],
]);

export enum Platform {
  WEB = 'web',
  MOBILE_IOS = 'mobile_ios',
  MOBILE_ANDROID = 'mobile_android',
  MOBILE_ALL = 'mobile_all',
  DESKTOP = 'desktop',
}

export const PlatformName = new Map([
  [Platform.WEB, '웹 사이트'],
  [Platform.MOBILE_IOS, '모바일 앱(iOS)'],
  [Platform.MOBILE_ANDROID, '모바일 앱(Android)'],
  [Platform.MOBILE_ALL, '모바일 앱'],
  [Platform.DESKTOP, '데스크탑 앱'],
]);

export enum MeetingType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const MeetingTypeName = new Map([
  [MeetingType.ONLINE, '온라인'],
  [MeetingType.OFFLINE, '오프라인'],
]);

//==============================================================
// END SaaS Enums
//==============================================================

//==============================================================
// START Team Enums
//==============================================================

export enum TeamRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  MEMBER = 'member',
}

export enum TeamProjectProgress {
  UPCOMING = 'upcoming',
  ONGOING = 'ongoing',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export const TeamProjectProgressName = new Map([
  [TeamProjectProgress.UPCOMING, '준비 중'],
  [TeamProjectProgress.ONGOING, '진행 중'],
  [TeamProjectProgress.FINISHED, '성공'],
  [TeamProjectProgress.FAILED, '실패'],
]);

//==============================================================
// END Team Enums
//==============================================================
