import { createSlice } from '@reduxjs/toolkit';
import { Platform } from '../../../lib/types/enums';
import { User } from '../../../lib/types/user';
import { Edit_01_Actions, Edit_02_Actions } from './types';

export interface CreateTeamProjectState {
  title: string;
  summary: string;
  platform: Platform | string;
  dueDate: string | undefined;
  selectedUsers: User[];
  templateId: string | null;
}

const initialState: CreateTeamProjectState = {
  title: '',
  summary: '',
  platform: '',
  dueDate: undefined,
  selectedUsers: [],
  templateId: null,
};

export const createTeamProjectSlice = createSlice({
  name: 'createTeamProject',
  initialState,
  reducers: {
    edit01: (state, action: Edit_01_Actions) => {
      state.title = action.payload.title;
      state.summary = action.payload.summary;
    },
    edit02: (state, action: Edit_02_Actions) => {
      state.platform = action.payload.platform;
      state.dueDate = action.payload.dueDate;
      state.selectedUsers = action.payload.selectedUsers;
      state.templateId = action.payload.templateId;
    },
    initialize: (state) => {
      state.title = initialState.title;
      state.summary = initialState.summary;
      state.platform = initialState.platform;
      state.dueDate = initialState.dueDate;
      state.selectedUsers = initialState.selectedUsers;
      state.templateId = initialState.templateId;
    },
  },
});

export const { edit01, edit02, initialize } = createTeamProjectSlice.actions;

export default createTeamProjectSlice.reducer;
