const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

export const HOSTING_URL = isProduction
  ? `https://server.swygbro.com`
  : isPreview
  ? 'https://staging.server.swygbro.com'
  : `http://localhost:8000`;
export const VERSION = 'v1';
export const HEADER = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};
