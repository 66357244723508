import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getProfileWithJWTApi } from '../../../lib/apis/auth';
import { UserRole as Role } from '../../../lib/types/enums';
import {
  Login_Actions,
  UpdatePhoneNumber_Actions,
  UpdateProfileImage_Actions,
  UpdateProfile_Actions,
  UserState,
} from './types';

const initialState: UserState = {
  requestStatus: undefined,
  isLoggedIn: false,
  userId: '',
  username: '',
  profileImage: '/assets/img/avatars/guest.png',
  email: '',
  phoneNumber: '',
  role: Role.GUEST,
  isActivated: false,
  userRole: {
    isUser: false,
    isAdmin: false,
    isStaff: false,
    isSuperUser: false,
  },
};

const asyncUserFetch = createAsyncThunk('users/asyncFetch', async () => {
  const res = await getProfileWithJWTApi();
  return res;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    login: (state, action: Login_Actions) => {
      state.isLoggedIn = true;
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.profileImage = action.payload.profileImage;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.userRole = action.payload.userRole;
      state.phoneNumber = action.payload.phoneNumber;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.userId = '';
      state.username = '';
      state.profileImage = '/assets/img/avatars/guest.png';
      state.email = '';
      state.role = Role.GUEST;
      state.userRole = {
        isUser: false,
        isAdmin: false,
        isStaff: false,
        isSuperUser: false,
      };
    },
    updateProfile: (state, action: UpdateProfile_Actions) => {
      state.role = action.payload.role;
      state.username = action.payload.username;
    },
    updateProfileImage: (state, action: UpdateProfileImage_Actions) => {
      state.profileImage = action.payload.profileImage;
    },
    updatePhoneNumber: (state, action: UpdatePhoneNumber_Actions) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    activateUser: (state) => {
      state.isActivated = true;
    },
    deactivateUser: (state) => {
      state.isActivated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncUserFetch.pending, (state) => {
      state.requestStatus = 'loading';
    });
    builder.addCase(asyncUserFetch.fulfilled, (state) => {
      state.requestStatus = 'success';
    });
    builder.addCase(asyncUserFetch.rejected, (state) => {
      state.requestStatus = 'fail';
    });
  },
});

export default usersSlice.reducer;
export const {
  login,
  logout,
  updateProfile,
  updateProfileImage,
  updatePhoneNumber,
  activateUser,
  deactivateUser,
} = usersSlice.actions;
export { asyncUserFetch };
