import Cookies from 'js-cookie';
import { Fragment, useEffect } from 'react';
import { useMutation } from 'react-query';
import { activateUserApi, deactivateUserApi } from '../../lib/apis/users';
import { useAppDispatch } from '../../redux/app/hooks';
import {
  activateUser,
  asyncUserFetch,
  deactivateUser,
  login,
  logout,
} from '../../redux/slices/users/usersSlice';

const App: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const token = Cookies.get('token');
  const { mutate: mutateActivateUser } = useMutation(
    ['activateUser'],
    activateUserApi,
  );
  const { mutate: mutateDeactivateUser } = useMutation(
    ['deactivateUser'],
    deactivateUserApi,
  );

  useEffect(() => {
    function handleBeforeUnload() {
      mutateDeactivateUser();
      dispatch(deactivateUser());
    }

    function handleVisibilityChange() {
      if (window.document.visibilityState === 'visible') {
        mutateActivateUser();
        dispatch(activateUser());
      } else {
        mutateDeactivateUser();
        dispatch(deactivateUser());
      }
    }

    if (token) {
      window.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      handleBeforeUnload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    dispatch(asyncUserFetch())
      .then((data) => {
        const {
          email,
          userRole,
          id: userId,
          profileImage,
          role,
          username,
          phoneNumber,
        } = data.payload;
        dispatch(
          login({
            userId,
            username,
            profileImage: profileImage.fileLocation,
            email,
            role,
            userRole,
            phoneNumber,
          }),
        );
        mutateActivateUser();
      })
      .catch(() => {
        dispatch(logout());
        mutateDeactivateUser();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
};

export default App;
