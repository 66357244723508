import { useEffect, useState } from 'react';
import { TextInput } from '..';

interface TagInputProps {
  className: string;
  placeholder?: string;
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  /** default 30 */
  inputMaxLength?: number;
}

const TagInput: React.FC<TagInputProps> = ({
  tags,
  className,
  setTags,
  inputMaxLength = 30,
  placeholder,
}) => {
  const [input, setInput] = useState('');

  useEffect(() => {
    const specialCharacters =
      /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    const formattedInput = input
      .replaceAll(' ', '')
      .replaceAll(specialCharacters, '')
      .toLowerCase();

    setInput(formattedInput);
  }, [input]);

  const onFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (tags.length >= 15) {
      setInput('');
      alert('15개를 초과하여 입력할 수 없습니다.');
      return;
    }
    if (input.length > 0) {
      const isExist = tags.find((tag) => tag === input) !== undefined;
      if (!isExist) {
        setTags((prevState) => {
          const updatedState = [...prevState];
          updatedState.push(input);
          return updatedState;
        });
        setInput('');
      } else {
        alert('이미 추가한 내용입니다.');
      }
    }
  };

  return (
    <div className="space-y-1">
      <form className={className}>
        <TextInput
          name="tag-input"
          value={input}
          onChange={setInput}
          placeholder={placeholder}
          maxLength={inputMaxLength}
        />
        <button onClick={onFormSubmit} className="sr-only" />
      </form>
      <div className="max-length-text">
        {input.length}/{inputMaxLength}
      </div>
    </div>
  );
};

export default TagInput;
