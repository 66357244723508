import {
  ChevronLeftIcon,
  ClockIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/outline';
import { MenuIcon, XCircleIcon } from '@heroicons/react/solid';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getRecommendTagsApi } from '../../lib/apis/projects';
import {
  postSearchHistoriesApi,
  postSearchHistoriesPublicApi,
} from '../../lib/apis/users';
import {
  postSearchHistoriesDto,
  postSearchHistoriesPublicDto,
} from '../../lib/apis/users/dto';
import { useClickOutside, useScrollBlock } from '../../lib/customHooks';
import { SearchType } from '../../lib/types/enums';
import { RecommendTag } from '../../lib/types/project';
import { useAppSelector } from '../../redux/app/hooks';
import MobileSideBar from './MobileSideBar';
import ProfileContainer from './ProfileContainer';

export const navigation = [
  { id: 1, name: '웹 콘텐츠', href: '/contents', target: '' },
  {
    id: 2,
    name: '호스팅 가이드',
    href: 'https://teamswyg.notion.site/SWYG-4673dcfd50024f45af908bd80e44d5dd?pvs=4',
    target: '_blank',
  },
  {
    id: 3,
    name: '웹개발 단기 프로젝트',
    href: 'https://swyp.swyg.im',
    target: '_blank',
  },
];

const NavBar = () => {
  const router = useRouter();
  const user = useAppSelector((state) => state.users);
  const word = router.query.word as string;
  const tag = router.query.tag as string;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const [isAutoSaveOn, setIsAutoSaveOn] = useState(true);
  const [searchWord, setSearchWord] = useState('');
  const [recentSearch, setRecentSearch] = useState<string[]>([]);
  const {
    isVisible: isDesktopVisible,
    ref: desktopRef,
    setIsVisible: setIsDesktopVisible,
  } = useClickOutside(false);
  const {
    isVisible: isTabletVisible,
    ref: tabletRef,
    setIsVisible: setIsTabletVisible,
  } = useClickOutside(false);
  const {
    isVisible: isMobileVisible,
    ref: mobileRef,
    setIsVisible: setIsMobileVisible,
  } = useClickOutside(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const { data: tagData, refetch: refetchTagData } = useQuery<RecommendTag[]>(
    ['recommendedTag', searchWord],
    () => getRecommendTagsApi({ tagName: searchWord.replace('#', '') }),
    { enabled: false },
  );
  const { mutate: mutateSearchHistories } = useMutation(
    ['searchHistories'],
    postSearchHistoriesApi,
  );
  const { mutate: mutateSearchHistoriesPublic } = useMutation(
    ['searchHistoriesPublic'],
    postSearchHistoriesPublicApi,
  );

  useEffect(() => {
    if (searchWord) {
      refetchTagData();
    }
  }, [searchWord, refetchTagData]);

  useEffect(() => {
    if (isMenuOpen) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [allowScroll, blockScroll, isMenuOpen]);

  useEffect(() => {
    const savedState = localStorage.getItem('autoSave');
    const recentWords = localStorage.getItem('searches');

    if (savedState) {
      const autoSave = JSON.parse(savedState) === 'true' ? true : false;
      setIsAutoSaveOn(autoSave);
    }
    if (recentWords) {
      const searches = JSON.parse(recentWords);
      setRecentSearch(searches);
    }
  }, []);

  useEffect(() => {
    if (word) {
      setIsSearchBarOpen(true);
      setSearchWord(word);
    } else if (tag) {
      setIsSearchBarOpen(true);
      setSearchWord(`#${tag}`);
    }
  }, [word, tag]);

  useEffect(() => {
    const searchItems = [
      {
        searchBar: 'search-bar-desktop',
        searchButton: 'search-button-desktop',
      },
      { searchBar: 'search-bar-tablet', searchButton: 'search-button-tablet' },
      { searchBar: 'search-bar-mobile', searchButton: 'search-button-mobile' },
    ];

    const handleSearch = (
      event: globalThis.KeyboardEvent,
      button: HTMLElement,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        button.click();
      }
    };

    searchItems.forEach((item) => {
      const searchBar = document.getElementById(item.searchBar);
      const searchButton = document.getElementById(item.searchButton);

      if (
        (isDesktopVisible || isTabletVisible || isMobileVisible) &&
        searchBar &&
        searchButton
      ) {
        searchBar.onkeydown = (event) => {
          handleSearch(event, searchButton);
        };
      }
    });
  }, [isDesktopVisible, isTabletVisible, isMobileVisible]);

  const onMenuButtonClick = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const onSearchBarClick = () => {
    setIsSearchBarOpen((prevState) => !prevState);
    if (isSearchBarOpen) {
      setIsTabletVisible(false);
      setIsMobileVisible(false);
    }
  };

  const onSearchBarChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchWord(event.target.value);
    setIsDesktopVisible(true);
    setIsTabletVisible(true);
    setIsMobileVisible(true);
  };

  const onSearchBarFocus = () => {
    setIsSearchBarFocused(true);
    setIsDesktopVisible(true);
    setIsTabletVisible(true);
    setIsMobileVisible(true);
  };

  const onSearchButtonClick = (word: string) => {
    if (word.replaceAll(' ', '')) {
      const isSearchWordExists = recentSearch.includes(word);
      const updatedSearches = isSearchWordExists
        ? [word, ...recentSearch.filter((search) => search !== word)]
        : [word, ...recentSearch];
      const searchType = word.includes('#') ? SearchType.TAG : SearchType.WORD;

      if (isAutoSaveOn) {
        localStorage.setItem('searches', JSON.stringify(updatedSearches));
        setRecentSearch(updatedSearches);
      }
      setIsDesktopVisible(false);
      setIsTabletVisible(false);
      setIsMobileVisible(false);
      setSearchWord(word);

      if (user.isLoggedIn) {
        const data: postSearchHistoriesDto = {
          searchWord: word.replace('#', ''),
          searchType,
        };
        mutateSearchHistories(data);
      } else {
        const data: postSearchHistoriesPublicDto = {
          searchWord: word.replace('#', ''),
          searchType,
        };
        mutateSearchHistoriesPublic(data);
      }
    }
  };

  const onDeleteButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    input: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const deletedSearches = [
      ...recentSearch.filter((search) => search !== input),
    ];
    localStorage.setItem('searches', JSON.stringify(deletedSearches));
    setRecentSearch(deletedSearches);
  };

  const onAutoSaveButtonClick = () => {
    const savedState = isAutoSaveOn ? 'false' : 'true';
    localStorage.setItem('autoSave', JSON.stringify(savedState));
    setIsAutoSaveOn((prevState) => !prevState);

    if (isAutoSaveOn) {
      onDeleteAllButtonClick();
    }
  };

  const onDeleteAllButtonClick = () => {
    localStorage.setItem('searches', JSON.stringify([]));
    setRecentSearch([]);
  };

  return (
    <>
      {/* Desktop */}
      <nav className="fixed z-50 hidden h-20 w-full select-none items-center border-b bg-white px-7 text-sm lg:flex">
        <Link href="/">
          <a className="relative mt-1 h-7 w-24 min-w-[96px] hover:opacity-70">
            <Image
              src="/assets/img/svg/logo.svg"
              alt="SWYG Logo"
              layout="fill"
              objectFit="contain"
            />
          </a>
        </Link>

        <div className="relative ml-5 mr-2 flex w-full items-center lg:mr-[14px]">
          <ul className="mr-4 mt-1 flex h-full items-center space-x-[18px]">
            {navigation.map((item) => (
              <li key={item.name} className="group flex h-full items-center">
                <Link href={item.href}>
                  <a
                    target={item.target}
                    className={`whitespace-nowrap text-sm group-hover:text-gray-900`}
                  >
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          <div className="relative flex w-full flex-col items-end">
            <div ref={desktopRef} className="w-full max-w-[782px]">
              <div
                className={`${
                  isSearchBarFocused
                    ? 'outline outline-2 outline-swygBlueButton-disabled'
                    : ''
                } flex h-10 w-full items-center justify-end rounded-lg bg-gray-100 pr-[88px] pl-5`}
              >
                <textarea
                  id="search-bar-desktop"
                  value={searchWord}
                  placeholder="검색어를 입력해 주세요."
                  onChange={onSearchBarChange}
                  onFocus={onSearchBarFocus}
                  onBlur={() => setIsSearchBarFocused(false)}
                  rows={1}
                  className="w-full resize-none whitespace-nowrap bg-transparent text-sm focus:outline-none"
                ></textarea>
                <div className="absolute right-0 flex">
                  {searchWord && (
                    <button
                      onClick={() => setSearchWord('')}
                      className="py-[14px] pl-5 pr-2"
                    >
                      <XCircleIcon width={16} height={16} />
                    </button>
                  )}
                  <Link
                    href={
                      searchWord.replaceAll(' ', '')
                        ? `/search/words/${searchWord.replace('#', '')}`
                        : '#'
                    }
                  >
                    <a
                      id="search-button-desktop"
                      onClick={() => onSearchButtonClick(searchWord)}
                      className="h-fit py-[10px] pl-[5px] pr-[15px]"
                    >
                      <SearchIcon width={24} height={24} />
                    </a>
                  </Link>
                </div>
              </div>
              {isDesktopVisible && (
                <div className="w-full max-w-[782px] translate-y-[2px] rounded-b-lg bg-white shadow-searchContainer md:rounded-lg lg:absolute lg:top-11 lg:right-0">
                  {searchWord ? (
                    tagData?.length ? (
                      <div className="flex-col border-b py-[9px]">
                        {tagData.slice(0, 10).map((tag) => (
                          <Link
                            key={tag.id}
                            href={`/search/tags/${tag.tagName}`}
                          >
                            <a
                              onClick={() =>
                                onSearchButtonClick(`#${tag.tagName}`)
                              }
                              className="flex flex-col space-y-2 px-4 py-[7px] hover:bg-gray-100"
                            >
                              <div className="text-sm text-gray-500">{`#${tag.tagName}`}</div>
                              <div className="text-xs text-gray-400">{`${tag.count}개`}</div>
                            </a>
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )
                  ) : recentSearch.length ? (
                    <div className="space-y-1 border-b py-4 text-sm text-gray-500">
                      {recentSearch.slice(0, 10).map((search, index) => (
                        <Link
                          key={index}
                          href={
                            search.includes('#')
                              ? `/search/tags/${search.replace('#', '')}`
                              : `/search/words/${search}`
                          }
                        >
                          <a
                            key={index}
                            onClick={() => onSearchButtonClick(search)}
                            className="flex justify-between px-4 py-[6px] hover:bg-gray-100"
                          >
                            <div className="flex items-center space-x-2">
                              <ClockIcon width={24} height={24} />
                              <div className="text-sm">{search}</div>
                            </div>
                            <button
                              onClick={(e) => onDeleteButtonClick(e, search)}
                              className="hover:text-gray-400"
                            >
                              <XIcon width={24} height={24} />
                            </button>
                          </a>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-1 border-b px-4 py-[22px] text-sm text-gray-500">
                      아직 검색 기록이 없네요. 검색어를 입력해 주세요!
                    </div>
                  )}

                  <div className="flex justify-between p-4 text-sm text-gray-400">
                    <button
                      onClick={onAutoSaveButtonClick}
                      className="hover:text-gray-700"
                    >{`자동저장 ${isAutoSaveOn ? '끄기' : '켜기'}`}</button>
                    <button
                      onClick={onDeleteAllButtonClick}
                      className="hover:text-gray-700"
                    >
                      전체삭제
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-1 items-center justify-end space-x-[13px]">
          {user.isLoggedIn ? (
            <ProfileContainer />
          ) : (
            <>
              <Link href="/auth/login">
                <a className="flex h-[42px] w-[88px] items-center justify-center rounded-[7px] border border-swygBlue-500 text-swygBlue-500">
                  로그인
                </a>
              </Link>
              <Link href="/auth/signup">
                <a className="btn-animation flex h-[42px] w-[88px] items-center justify-center rounded-[7px] bg-swygBlue-500 text-gray-100">
                  회원가입
                </a>
              </Link>
            </>
          )}
        </div>
      </nav>

      {/* Tablet */}
      <nav className="fixed z-50 hidden h-20 w-full select-none items-center border-b bg-white px-7 text-sm md:flex lg:hidden">
        <Link href="/">
          <a className="relative mt-1 h-7 w-24 min-w-[96px] hover:opacity-70">
            <Image
              src="/assets/img/svg/logo.svg"
              alt="SWYG Logo"
              layout="fill"
              objectFit="contain"
            />
          </a>
        </Link>

        <div className="relative ml-5 mr-2 flex w-full items-center justify-between">
          <ul className="mr-4 mt-1 flex h-full items-center space-x-[18px]">
            {navigation.map((item) => (
              <li key={item.name} className="group flex h-full items-center">
                <Link href={item.href}>
                  <a
                    target={item.id === 2 ? '_blank' : ''}
                    className={`whitespace-nowrap text-sm group-hover:text-gray-900`}
                  >
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          {!isSearchBarOpen ? (
            <button onClick={onSearchBarClick} className="mr-4">
              <SearchIcon width={24} height={24} />
            </button>
          ) : (
            <div className="absolute left-0 -top-[7px] flex w-full items-start">
              <div className="flex h-10 items-center bg-white pr-3">
                <button onClick={onSearchBarClick}>
                  <ChevronLeftIcon width={24} height={24} />
                </button>
              </div>
              <div
                ref={tabletRef}
                className="flex w-full flex-col items-end lg:relative lg:top-0"
              >
                <div
                  className={`${
                    isSearchBarFocused
                      ? 'outline outline-2 outline-swygBlueButton-disabled'
                      : ''
                  } flex h-10 w-full max-w-[782px] items-center justify-end rounded-lg bg-gray-100 pr-[88px] pl-5`}
                >
                  <textarea
                    id="search-bar-tablet"
                    value={searchWord}
                    placeholder="검색어를 입력해 주세요."
                    onChange={onSearchBarChange}
                    onFocus={onSearchBarFocus}
                    onBlur={() => setIsSearchBarFocused(false)}
                    rows={1}
                    className="w-full resize-none whitespace-nowrap bg-transparent text-sm focus:outline-none"
                  ></textarea>
                  <div className="absolute right-0 flex">
                    {searchWord && (
                      <button
                        onClick={() => setSearchWord('')}
                        className="py-[14px] pl-5 pr-2"
                      >
                        <XCircleIcon width={16} height={16} />
                      </button>
                    )}
                    <Link
                      href={
                        searchWord.replaceAll(' ', '')
                          ? `/search/words/${searchWord.replace('#', '')}`
                          : '#'
                      }
                    >
                      <a
                        id="search-button-tablet"
                        onClick={() => onSearchButtonClick(searchWord)}
                        className="h-fit -translate-x-[1px] py-[10px] pl-[5px] pr-[15px]"
                      >
                        <SearchIcon width={24} height={24} />
                      </a>
                    </Link>
                  </div>
                </div>
                {isTabletVisible && (
                  <div className="w-full max-w-[782px] translate-y-[2px] rounded-b-lg bg-white shadow-searchContainer md:rounded-lg lg:absolute lg:top-11 lg:right-0">
                    {searchWord ? (
                      tagData?.length ? (
                        <div className="flex flex-col border-b py-[9px]">
                          {tagData.slice(0, 6).map((tag) => (
                            <Link
                              key={tag.id}
                              href={`/search/tags/${tag.tagName}`}
                            >
                              <a
                                onClick={() =>
                                  onSearchButtonClick(`#${tag.tagName}`)
                                }
                                className="flex flex-col space-y-2 px-4 py-[7px] hover:bg-gray-100"
                              >
                                <div className="text-sm text-gray-500">{`#${tag.tagName}`}</div>
                                <div className="text-xs text-gray-400">{`${tag.count}개`}</div>
                              </a>
                            </Link>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )
                    ) : recentSearch.length ? (
                      <div className="space-y-1 border-b py-4 text-sm text-gray-500">
                        {recentSearch.slice(0, 6).map((search, index) => (
                          <Link
                            key={index}
                            href={
                              search.includes('#')
                                ? `/search/tags/${search.replace('#', '')}`
                                : `/search/words/${search}`
                            }
                          >
                            <a
                              key={index}
                              onClick={() => onSearchButtonClick(search)}
                              className="flex justify-between px-4 py-[6px] hover:bg-gray-100"
                            >
                              <div className="flex items-center space-x-2">
                                <ClockIcon width={24} height={24} />
                                <div className="text-sm">{search}</div>
                              </div>
                              <button
                                onClick={(e) => onDeleteButtonClick(e, search)}
                                className="hover:text-gray-400"
                              >
                                <XIcon width={24} height={24} />
                              </button>
                            </a>
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-col space-y-1 border-b px-4 py-[22px] text-sm text-gray-500">
                        아직 검색 기록이 없네요. 검색어를 입력해 주세요!
                      </div>
                    )}

                    <div className="flex justify-between p-4 text-sm text-gray-400">
                      <button
                        onClick={onAutoSaveButtonClick}
                        className="hover:text-gray-700"
                      >{`자동저장 ${isAutoSaveOn ? '끄기' : '켜기'}`}</button>
                      <button
                        onClick={onDeleteAllButtonClick}
                        className="hover:text-gray-700"
                      >
                        전체삭제
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-1 items-center justify-end space-x-[13px]">
          {user.isLoggedIn ? (
            <Link href={`/profiles/${user.userId}`}>
              <a>
                <ProfileContainer />
              </a>
            </Link>
          ) : (
            <>
              <Link href="/auth/login">
                <a className="flex h-[42px] w-[88px] items-center justify-center rounded-[7px] border border-swygBlue-500 text-swygBlue-500">
                  로그인
                </a>
              </Link>
              <Link href="/auth/signup">
                <a className="btn-animation flex h-[42px] w-[88px] items-center justify-center rounded-[7px] bg-swygBlue-500 text-gray-100">
                  회원가입
                </a>
              </Link>
            </>
          )}
        </div>
      </nav>

      {/* Mobile */}
      {!isSearchBarOpen ? (
        <nav className="fixed z-50 flex h-16 w-full select-none items-center justify-between border-b bg-white p-[6px] text-sm md:hidden">
          <button onClick={onMenuButtonClick} className="p-[10px]">
            <MenuIcon width={24} height={24} />
          </button>

          <MobileSideBar
            isMenuOpen={isMenuOpen}
            onMenuButtonClick={onMenuButtonClick}
          />

          <Link href="/">
            <a className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform font-logo text-xl font-bold text-swygBlue-500">
              SWYG
            </a>
          </Link>

          <div className="flex items-center">
            <button onClick={onSearchBarClick} className="h-fit p-[10px]">
              <SearchIcon width={24} height={24} />
            </button>
            {!user.isLoggedIn && (
              <Link href="/auth/login">
                <a className="flex h-11 shrink-0 items-center justify-center pl-[6px] pr-[11px] text-sm">
                  로그인
                </a>
              </Link>
            )}
          </div>
        </nav>
      ) : (
        <div className="fixed z-50 w-full md:hidden">
          <nav className="flex h-16 w-full select-none items-center justify-between border-b bg-white pl-[6px]">
            <button onClick={onSearchBarClick} className="p-[10px]">
              <ChevronLeftIcon width={24} height={24} />
            </button>
            <div
              ref={mobileRef}
              className="ml-[6px] mr-4 flex w-full items-center justify-between"
            >
              <div
                className={`${
                  isSearchBarFocused
                    ? 'outline outline-2 outline-swygBlueButton-disabled'
                    : ''
                } flex h-9 w-full items-center rounded-lg bg-gray-100 pl-4 pr-[88px]`}
              >
                <textarea
                  id="search-bar-mobile"
                  value={searchWord}
                  onChange={onSearchBarChange}
                  onFocus={onSearchBarFocus}
                  onBlur={() => setIsSearchBarFocused(false)}
                  placeholder="검색어를 입력해 주세요."
                  rows={1}
                  className="w-full resize-none whitespace-nowrap bg-transparent text-sm focus:outline-none"
                ></textarea>
              </div>
              <div className="absolute right-4 flex">
                {searchWord && (
                  <button
                    onClick={() => setSearchWord('')}
                    className="py-[14px] pl-5 pr-2"
                  >
                    <XCircleIcon width={16} height={16} />
                  </button>
                )}
                <Link
                  href={
                    searchWord.replaceAll(' ', '')
                      ? `/search/words/${searchWord.replace('#', '')}`
                      : '#'
                  }
                >
                  <a
                    id="search-button-mobile"
                    onClick={() => onSearchButtonClick(searchWord)}
                    className="h-fit py-[10px] pl-[5px] pr-[15px] text-gray-900 hover:text-gray-500"
                  >
                    <SearchIcon width={24} height={24} />
                  </a>
                </Link>
              </div>
              {isMobileVisible && (
                <div className="absolute top-16 left-0 w-full rounded-b-lg bg-white shadow-searchContainer">
                  {searchWord ? (
                    tagData?.length ? (
                      <div className="flex flex-col border-b py-[11px]">
                        {tagData.slice(0, 8).map((tag) => (
                          <Link
                            key={tag.id}
                            href={`/search/tags/${tag.tagName}`}
                          >
                            <a
                              onClick={() =>
                                onSearchButtonClick(`#${tag.tagName}`)
                              }
                              className="flex flex-col space-y-2 px-4 py-[7px] hover:bg-gray-100"
                            >
                              <div className="text-sm text-gray-500">{`#${tag.tagName}`}</div>
                              <div className="text-xs text-gray-400">{`${tag.count}개`}</div>
                            </a>
                          </Link>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )
                  ) : recentSearch.length ? (
                    <div className="flex flex-col space-y-1 border-b py-1 text-sm text-gray-500">
                      {recentSearch.slice(0, 10).map((search, index) => (
                        <Link
                          key={index}
                          href={
                            search.includes('#')
                              ? `/search/tags/${search.replace('#', '')}`
                              : `/search/words/${search}`
                          }
                        >
                          <a
                            onClick={() => onSearchButtonClick(search)}
                            className="flex justify-between px-4 py-[6px] hover:bg-gray-100"
                          >
                            <div className="flex items-center space-x-2">
                              <ClockIcon width={24} height={24} />
                              <div className="text-sm">{search}</div>
                            </div>
                            <button
                              onClick={(e) => onDeleteButtonClick(e, search)}
                              className="hover:text-gray-400"
                            >
                              <XIcon width={24} height={24} />
                            </button>
                          </a>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col space-y-1 border-b px-4 py-[22px] text-sm text-gray-500">
                      아직 검색 기록이 없네요. 검색어를 입력해 주세요!
                    </div>
                  )}
                  <div className="flex justify-between p-4 text-sm text-gray-400">
                    <button
                      onClick={onAutoSaveButtonClick}
                      className="hover:text-gray-700"
                    >{`자동저장 ${isAutoSaveOn ? '끄기' : '켜기'}`}</button>
                    <button
                      onClick={onDeleteAllButtonClick}
                      className="hover:text-gray-700"
                    >
                      전체삭제
                    </button>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default NavBar;
