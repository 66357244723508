import axios from 'axios';
import { HEADER, HOSTING_URL, VERSION } from '../config';
import {
  CreateCareerDto,
  CreatePortfolioDto,
  DeleteCareerDto,
  DeletePortfolioDto,
  FollowUserDto,
  GetCareerDto,
  GetCommonProfilesDto,
  GetPortfolioDto,
  GetProfileFavProjectsCountDto,
  GetProfileFavProjectsDto,
  GetProfileMyProjectsCountDto,
  GetProfileProjectsDto,
  GetProfileSpecificDto,
  GetProfileSummaryDto,
  GetSpecificCareerDto,
  GetSpecificPortfolioDto,
  GetUserDto,
  PostDashboardMemoDto,
  SendPhoneConfirmationCodeDto,
  UpdateAccountProfileDto,
  UpdateCareerDto,
  UpdatePasswordDto,
  UpdatePhoneNumberDto,
  UpdatePortfolioDto,
  UpdatePreferFieldsDto,
  UpdatePublicProfileDto,
  UpdateSNSLinksDto,
  UpdateToolsDto,
  UploadPortfolioImagesDto,
  UploadProfileImageDto,
  postSearchHistoriesDto,
  postSearchHistoriesPublicDto,
} from './dto';

//==============================================================
// START SSG Api
//==============================================================

export const getUserIds = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/ids`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

//==============================================================
// END SSG Api
//==============================================================

//==============================================================
// START Follow Api
//==============================================================

export const getMyFollowingsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/follow`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const followUserApi = async (data: FollowUserDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/follow/${userId}`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
  });
};

export const unfollowUserApi = async (data: FollowUserDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/follow/${userId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

//==============================================================
// END Follow Api
//==============================================================

//==============================================================
// START Profile Api
//==============================================================

// Overall
//==============================================================

export const getCommonProfilesApi = async (data: GetCommonProfilesDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/common`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getProfileProjectsApi = async (data: GetProfileProjectsDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/projects`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getProfileFavProjectsApi = async (
  data: GetProfileFavProjectsDto,
) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/projects/favs`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getProfileMyProjectsCountApi = async (
  data: GetProfileMyProjectsCountDto,
) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/projects/count`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getProfileFavProjectsCountApi = async (
  data: GetProfileFavProjectsCountDto,
) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/projects/favs/count`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getProfileSummaryApi = async (data: GetProfileSummaryDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/${userId}/summary`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Public
//==============================================================

export const getPublicProfileApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/public`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const updatePublicProfileApi = async (data: UpdatePublicProfileDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/public`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

export const uploadProfileImageApi = async (data: UploadProfileImageDto) => {
  const { file } = data;
  const formData = new FormData();
  formData.append('imageFile', file);
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/public/images`;
  return axios({
    method: 'post',
    url,
    headers: { ...HEADER, enctype: 'multipart/form-data' },
    data: formData,
  });
};

export const deleteProfileImageApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/public/images`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

// Account
//==============================================================

export const getAccountProfileApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/accounts`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const updateAccountProfileApi = async (
  data: UpdateAccountProfileDto,
) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/accounts`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

export const sendPhoneConfirmationCodeApi = async (
  data: SendPhoneConfirmationCodeDto,
) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/accounts/phonenumber`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const updatePhoneNumberApi = async (data: UpdatePhoneNumberDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/accounts/phonenumber`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

// Password
//========================================================

export const updatePasswordApi = async (data: UpdatePasswordDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/passwords`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

// Tool
//========================================================

export const getToolsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/tools`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const updateToolsApi = async (data: UpdateToolsDto[]) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/tools`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

// Field
//========================================================

export const getPreferFieldsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/fields`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const updatePreferFieldsApi = async (data: UpdatePreferFieldsDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/fields`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

// Portfolio
//========================================================

export const uploadPortfolioImagesApi = async (
  data: UploadPortfolioImagesDto,
) => {
  const { descriptions, imageFiles, portfolioId } = data;
  const formData = new FormData();
  for (const imageFile of imageFiles) {
    formData.append('imageFiles', imageFile);
  }
  for (const description of descriptions) {
    formData.append('descriptions', description);
  }
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios/images/${portfolioId}`;
  return axios({
    method: 'post',
    url,
    headers: { ...HEADER, enctype: 'multipart/form-data' },
    data: formData,
  });
};

export const createPortfolioApi = async (data: CreatePortfolioDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const getPortfoliosApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const getPortfolioApi = async (data: GetPortfolioDto) => {
  const { portfolioId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios/${portfolioId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updatePortfolioApi = async (data: UpdatePortfolioDto) => {
  const { portfolioId, ...portfolioData } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios/${portfolioId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: portfolioData,
  });
};

export const deletePortfolioApi = async (data: DeletePortfolioDto) => {
  const { portfolioId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/portfolios/${portfolioId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

// Career
//========================================================

export const createCareerApi = async (data: CreateCareerDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/careers`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const getCareersApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/careers`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const getCareerApi = async (data: GetCareerDto) => {
  const { careerId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/careers/${careerId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const updateCareerApi = async (data: UpdateCareerDto) => {
  const { careerId, ...careerData } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/careers/${careerId}`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data: careerData,
  });
};

export const deleteCareerApi = async (data: DeleteCareerDto) => {
  const { careerId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/careers/${careerId}`;
  return axios({
    method: 'delete',
    url,
    headers: HEADER,
  });
};

// SNS Link
//========================================================

export const getSNSLinksApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/sns`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const updateSNSLinksApi = async (data: UpdateSNSLinksDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/profiles/sns`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
    data,
  });
};

// Notification
//========================================================

export const getAlarmsNotificationApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/notifications`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const enableSendEmailApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/email/enable`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

export const disableSendEmailApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/email/disable`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

// Search Histories
//========================================================

export const postSearchHistoriesApi = async (data: postSearchHistoriesDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/search/histories`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const postSearchHistoriesPublicApi = async (
  data: postSearchHistoriesPublicDto,
) => {
  const url = `${HOSTING_URL}/${VERSION}/users/search/histories/public`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

//==============================================================
// END Profile Api
//==============================================================

//==============================================================
// START Profile Specific Api
//==============================================================

// Tool
//========================================================

export const getSpecificToolsApi = async (data: GetProfileSpecificDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/tools`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Field
//========================================================

export const getSpecificFieldsApi = async (data: GetProfileSpecificDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/fields`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Portfolio
//========================================================

export const getSpecificPortfoliosApi = async (data: GetProfileSpecificDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/portfolios`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getSpecificPortfolioApi = async (
  data: GetSpecificPortfolioDto,
) => {
  const { userId, portfolioId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/portfolios/${portfolioId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

// Career
//========================================================

export const getSpecificCareersApi = async (data: GetProfileSpecificDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/careers`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

export const getSpecificCareerApi = async (data: GetSpecificCareerDto) => {
  const { userId, careerId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/specific/${userId}/careers/${careerId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

//==============================================================
// END Profile Specific Api
//==============================================================

//==============================================================
// START Dashboard Api
//==============================================================

// Project
//==============================================================

export const getMyProjectsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/projects`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

export const getMySchedulesApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/schedules`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

// Team
//==============================================================

export const getMyTeamsApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/teams`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

// Memo
//==============================================================

export const postDashboardMemoApi = async (data: PostDashboardMemoDto) => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/memos`;
  return axios({
    method: 'post',
    url,
    headers: HEADER,
    data,
  });
};

export const getDashboardMemoApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/memos`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

// Config
//==============================================================

export const getDashboardConfigApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/dashboard/configs`;
  const { data } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return data;
};

//==============================================================
// END Dashboard Api
//==============================================================

//==============================================================
// START Default CRUD Api
//==============================================================

export const getUserApi = async (data: GetUserDto) => {
  const { userId } = data;
  const url = `${HOSTING_URL}/${VERSION}/users/${userId}`;
  const { data: axiosData } = await axios({
    method: 'get',
    url,
    headers: HEADER,
  });
  return axiosData;
};

//==============================================================
// END Default CRUD Api
//==============================================================

//==============================================================
// Start User Login Activate Api
//==============================================================

export const activateUserApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/activation/up`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

export const deactivateUserApi = async () => {
  const url = `${HOSTING_URL}/${VERSION}/users/activation/down`;
  return axios({
    method: 'patch',
    url,
    headers: HEADER,
  });
};

//==============================================================
// END User Login Activate Api
//==============================================================
