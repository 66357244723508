import { createSlice } from '@reduxjs/toolkit';
import { Submit_Actions } from './types';

export interface CreateProjectState {
  title: string;
  summary: string;
  tags: string[];
}

const initialState: CreateProjectState = {
  title: '',
  summary: '',
  tags: [],
};

export const createProjectSlice = createSlice({
  name: 'createProject',
  initialState,
  reducers: {
    submit: (state, action: Submit_Actions) => {
      state.title = action.payload.title;
      state.summary = action.payload.summary;
      state.tags = action.payload.tags;
    },
    initialize: (state) => {
      state.title = initialState.title;
      state.summary = initialState.summary;
      state.tags = initialState.tags;
    },
  },
});

export const { submit, initialize } = createProjectSlice.actions;

export default createProjectSlice.reducer;
